const OrderStep = (props: {
    icon: string | JSX.Element;
    icon2: string | JSX.Element;
    title: string;
    subtitle?: string;
    bg: string;
    border?: string;
    isDone?: boolean;
    variant?: 'last';
}) => {
    const { icon, icon2, title, subtitle, bg, border, variant, isDone } = props;

    return (
        <div className="flex w-full items-center justify-between pt-5">
            <div className="flex items-center gap-3">
                <div
                    className={`flex ${
                        variant === 'last'
                            ? ' '
                            : 'from-[#ffffff0f] to-white/0 shadow-xl shadow-gray-200 dark:bg-gradient-to-b dark:shadow-darkinset dark:shadow-white/20'
                    } h-8 w-8 items-center justify-center rounded-full text-base text-leadrs dark:text-white ${bg}`}
                >
                    {icon}
                </div>
                <div>
                    <h4 className="text-xs font-medium leading-6 text-navy-700 dark:text-white">
                        {' '}
                        {title}{' '}
                    </h4>
                    <p className="text-xs text-gray-600"> {subtitle} </p>
                </div>
            </div>
            <div
                className={`flex h-8 w-8 items-center justify-center rounded-full ${border} !font-bold ${
                    isDone ? 'text-green-500' : 'text-gray-300'
                } `}
            >
                {icon2}
            </div>
        </div>
    );
};

export default OrderStep;
