import { BsRobot } from 'react-icons/bs';
import {
    FaGoogle,
    FaInstagram,
    FaLinkedin,
    FaPinterest,
    FaTiktok,
    FaTwitter,
} from 'react-icons/fa';
import { FaMeta } from 'react-icons/fa6';
import { IoNewspaperOutline, IoSearchOutline } from 'react-icons/io5';
import { MdAdsClick, MdMail, MdSms } from 'react-icons/md';
import { RiAdvertisementLine } from 'react-icons/ri';
import { TbBrandBing } from 'react-icons/tb';

export const ChannelIcon = (props: { channel: string }) => {
    const { channel } = props;

    return (
        <>
            {channel === 'Facebook' && <FaMeta />}
            {channel === 'Instagram' && <FaInstagram />}
            {channel === 'Google Ads' && <FaGoogle />}
            {channel === 'LinkedIn' && <FaLinkedin />}
            {channel === 'Emailing' && <MdMail />}
            {channel === 'Display' && <MdAdsClick />}
            {channel === 'Pinterest' && <FaPinterest />}
            {channel === 'SMS' && <MdSms />}
            {channel === 'Tik Tok' && <FaTiktok />}
            {channel === 'Bing Ads' && <TbBrandBing />}
            {channel === 'Twitter' && <FaTwitter />}
            {channel === 'Robot I.A' && <BsRobot />}
            {channel === 'Newsletter' && <IoNewspaperOutline />}
            {channel === 'SEO' && <IoSearchOutline />}
            {channel === 'Taboola' && <RiAdvertisementLine />}
        </>
    );
};
