import { useEffect, useState } from 'react';
import StepperControl from './components/StepperControl';
import Card from 'components/card';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Checkbox from 'components/checkbox';
import Select from 'react-select';
import InputField from 'components/fields/InputField';
import {
    allChannels,
    defaultPricing,
    frenchAreas,
    industries,
    industriesPricing,
} from 'variables/industries';
import { ChannelIcon } from 'components/icons';

const ProductNew = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState('');
    const [offerCreated, setOfferCreated] = useState(false);

    const [channels, setChannels] = useState([]);
    const [industry, setIndustry] = useState('Rénovation');
    const [subIndustry, setSubIndustry] = useState('Pompe à chaleur');
    const [fields, setFields] = useState([]);
    const [user, setUser] = useState<any>(null);

    const [offer, setOffer] = useState<any>({
        type: 'leads',
        channels: [],
        industry: 'Rénovation',
        subIndustry: 'Pompe à chaleur',
        regions: ['Toute la France'],
        fields: ['prénom', 'nom', 'email', 'téléphone'],
        whiteLabelInfos: '',
        weeklyQuantity: '',
        targetPrice: '25-30',
        franceOnly: true,
        //leadNbr: "",
    });

    const handlechange = (e: any) => {
        const { id, value } = e.target;

        if (id === 'weeklyQuantity' && value > 500) {
            setOffer({ ...offer, [id]: 500 });
        } else {
            setOffer({ ...offer, [id]: value });
        }
        setError('');
    };

    const getPricing: any = () => {
        const indus = window.localStorage.getItem('industry');
        if (industriesPricing[indus]) return industriesPricing[indus].pricing;
        return defaultPricing;
    };

    const handleFieldsChange = (e: any) => {
        const { id, checked } = e.target;
        let newFields = [...fields, id];
        newFields = [
            ...new Set([...newFields, ...['Téléphone', 'Nom', 'Prénom']]),
        ];

        if (checked) {
            setFields(newFields);
        } else {
            newFields = fields.filter((fields) => fields !== id);
            setFields(newFields);
        }
        let newE = {
            target: {
                id: 'fields',
                value: newFields,
            },
        };
        handlechange(newE);
    };

    const handleChannelChange = (e: any) => {
        const { id, checked } = e.target;
        let newChannels = [...channels, id];
        if (checked) {
            setChannels(newChannels);
        } else {
            newChannels = channels.filter((channel) => channel !== id);
            setChannels(newChannels);
        }
        let newE = {
            target: {
                id: 'channels',
                value: newChannels,
            },
        };
        handlechange(newE);
    };

    const handleIndustryChange = (e: any) => {
        const { value } = e.target;
        window.localStorage.setItem('industry', value);
        setIndustry(value);
        setSubIndustry(industries[value][0].name);
        setOffer({
            ...offer,
            industry: value,
            subIndustry: industries[value][0].name,
        });
    };

    const handleSubIndustryChange = (e: any) => {
        const { value } = e.target;
        setSubIndustry(value);
        handlechange(e);
    };

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    useEffect(() => {
        window.localStorage.setItem('industry', industry);
        initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(offer.industry, offer.subIndustry);
    }, [offer]);

    const steps = [
        { stepNo: 1, name: 'Créer une offre', error: error },
        { stepNo: 2, name: 'Pricing', error: error },
    ];

    const createOffer = () => {
        if (offerCreated) return;
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        setOfferCreated(true);
        axios
            .post(`${CONFIG.apiUrl}/offer`, offer, config)
            .then((res: any) => {
                if (user.role === 'paying-user')
                    window.location.href = '/admin/my-request?created=true';
                else window.location.href = '/admin/my-request?not-paid=true';
            })
            .catch((err: any) => {
                setError(err.response.data.error);
                setOfferCreated(false);
            });
    };

    const handleClick = (direction: string) => {
        console.log(offer);
        let newStep = currentStep;

        direction === 'next' ? newStep++ : newStep--;
        // check if steps are within bounds
        if (newStep > 0 && newStep <= steps.length) {
            if (offer.channels.length > 0) setCurrentStep(newStep);
            else setError('Veuillez remplir tous les champs obligatoires');
        }
        if (newStep === 3) {
            console.log('submit');
            createOffer();
        }
    };

    return (
        <div className="mt-3 h-full w-full">
            <div className="absolute z-10 ml-10 mt-5 items-center text-center font-poppins text-xl font-bold text-white">
                Création d'une commande
            </div>

            <div className="z-0 h-[300px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[300px]" />
            <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
                <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]"></div>
                <Card extra={'h-full mx-auto border pb-3'}>
                    <div className="rounded-[20px]">
                        {/* Stepper One */}
                        <div
                            className={`${
                                steps[currentStep - 1].stepNo === 2 && 'hidden'
                            } h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}
                        >
                            {/* Header */}

                            {/* Error message */}
                            {error && (
                                <div className="z-10 ml-1 mt-2 items-center font-poppins text-[14px] text-red-500">
                                    {error}.
                                </div>
                            )}

                            {/* content */}
                            {/* inputs */}
                            <div className="grid grid-cols-2 gap-3">
                                <div className="col-span-2 md:col-span-1">
                                    {/* Type */}
                                    <div className="mb-0">
                                        <label
                                            htmlFor="currency"
                                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                        >
                                            Départements*
                                        </label>
                                        <div className="mt-2 w-full rounded-xl px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                            <Select
                                                defaultValue={[
                                                    {
                                                        value: 'france',
                                                        label: 'Toute la France',
                                                    },
                                                ]}
                                                isMulti
                                                onChange={(items) => {
                                                    const regions: any = [];
                                                    items.map((item) =>
                                                        regions.push(item.value)
                                                    );

                                                    let newE = {
                                                        target: {
                                                            id: 'regions',
                                                            value: regions,
                                                        },
                                                    };
                                                    handlechange(newE);
                                                }}
                                                name="colors"
                                                options={frenchAreas.map(
                                                    (item) => ({
                                                        value: item,
                                                        label: item,
                                                    })
                                                )}
                                                className="w-full"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </div>
                                    {/* channels */}
                                    <div className="mt-3">
                                        <label
                                            htmlFor="currency"
                                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                        >
                                            Canaux d'acquisition*
                                        </label>

                                        <div className="ml-3 mt-4 grid grid-cols-2 gap-2 xl:grid-cols-3">
                                            {allChannels.map((channel) => (
                                                <div className="col-span-1 flex">
                                                    <Checkbox
                                                        defaultChecked={false}
                                                        id={channel}
                                                        onChange={
                                                            handleChannelChange
                                                        }
                                                    />
                                                    <div className="ml-2">
                                                        <ChannelIcon
                                                            channel={channel}
                                                        />
                                                    </div>
                                                    <p className="ml-1 text-xs">
                                                        {channel}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/* right side */}
                                <div className="col-span-2 md:col-span-1">
                                    {/* Type */}
                                    <div className="mb-0 mt-4">
                                        <label
                                            htmlFor="currency"
                                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                        >
                                            Industrie*
                                        </label>
                                        <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                            <select
                                                id="industry"
                                                className="mb-0 w-full bg-white dark:!bg-navy-800"
                                                onChange={handleIndustryChange}
                                            >
                                                {Object.keys(industries).map(
                                                    (item) => (
                                                        <option value={item}>
                                                            {item}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    {/* Sous-Industrie */}
                                    <div className="mb-0 mt-5">
                                        <label
                                            htmlFor="currency"
                                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                        >
                                            Sous-Industrie*
                                        </label>
                                        <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                            <select
                                                id="subIndustry"
                                                className="mb-0 w-full bg-white dark:!bg-navy-800"
                                                onChange={
                                                    handleSubIndustryChange
                                                }
                                            >
                                                {industries[industry].map(
                                                    (item: any) => (
                                                        <option
                                                            value={item.name}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    {(industry === 'Autre' ||
                                        subIndustry === 'Autre') && (
                                        <div className="mb-0 ml-2 mt-2">
                                            <label
                                                htmlFor={'others'}
                                                className={`ml-1.5 text-sm font-bold text-navy-700 dark:text-white`}
                                            >
                                                Autre
                                            </label>
                                            <p className="mt-2 text-sm">
                                                Contactez charlotte@leadrs.co
                                                pour ajouter votre industrie sur
                                                Leadrs
                                            </p>
                                        </div>
                                    )}

                                    {/* SSous-sous-Industrie */}
                                    {/* find element in industries[industry] who contain subIndustry */}
                                    {industries[industry].find(
                                        (item: any) => item.name === subIndustry
                                    ).sub.length > 0 && (
                                        <div className="mb-0 mt-3">
                                            <label
                                                htmlFor="currency"
                                                className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                            >
                                                Détails sous-industrie*
                                            </label>
                                            <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                                <select
                                                    id="subIndustry"
                                                    className="mb-0 w-full bg-white dark:!bg-navy-800"
                                                    onChange={handlechange}
                                                >
                                                    {/* Find industries[industry] where name === subIndustry */}
                                                    {industries[industry].map(
                                                        (item: any) => {
                                                            if (
                                                                item.name ===
                                                                subIndustry
                                                            ) {
                                                                return item.sub.map(
                                                                    (
                                                                        subItem: any
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                subItem
                                                                            }
                                                                        >
                                                                            {
                                                                                subItem
                                                                            }
                                                                        </option>
                                                                    )
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {/* text area */}
                                    {/* <div className="mt-5">
                                        <TextField
                                            label="Argument de vente*"
                                            placeholder="Avantage concurentiel, script commercial, ..."
                                            id="whiteLabelInfos"
                                            onChange={handlechange}
                                            cols={30}
                                            rows={4}
                                        />
                                    </div>
                                    <div className="mt-7">
                                        <TextField
                                            label="Site concurrents"
                                            placeholder="pompes-chaleur.com, pompes-a-chaleur.fr, ..."
                                            id="competitors"
                                            onChange={handlechange}
                                            cols={30}
                                            rows={4}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* Stepper Two */}
                        <div
                            className={`${
                                steps[currentStep - 1].stepNo === 1 && 'hidden'
                            } h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}
                        >
                            {/* Header */}
                            <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                                Pricing
                            </h1>
                            {/* Error message */}
                            {error && (
                                <div className="z-10 ml-1 mt-2 items-center font-poppins text-[14px] text-red-500">
                                    {error}.
                                </div>
                            )}
                            {/* inputs */}
                            <div className="mt-10 grid h-full w-full grid-cols-2 gap-3">
                                {/* left side */}
                                <div className="col-span-12 grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
                                    <div className="mb-0">
                                        <label
                                            htmlFor="currency"
                                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                        >
                                            Prix cible HT*
                                        </label>
                                        <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                            <select
                                                id="targetPrice"
                                                className="mb-0 w-full bg-white dark:!bg-navy-800"
                                                onChange={handlechange}
                                            >
                                                {getPricing().map(
                                                    (price: string) => (
                                                        <option value={price}>
                                                            {price}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <InputField
                                        label="Quantité de leads (500 max)"
                                        placeholder="260"
                                        id="weeklyQuantity"
                                        type="number"
                                        onChange={handlechange}
                                    />
                                </div>
                                <div className="col-span-12 mt-3">
                                    <label
                                        htmlFor="currency"
                                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                    >
                                        Critères de votre lead
                                    </label>
                                    <div className="ml-3 mt-4 grid grid-cols-12">
                                        {industriesPricing[industry].fields
                                            .concat([
                                                'Téléphone',
                                                'Email',
                                                'Nom',
                                                'Prénom',
                                            ])
                                            .reverse()
                                            .map((field: string) => (
                                                <div className="col-span-4 mt-2">
                                                    <Checkbox
                                                        defaultChecked={[
                                                            'Téléphone',
                                                            'Nom',
                                                            'Prénom',
                                                        ].includes(field)}
                                                        id={field}
                                                        extra="mt-1"
                                                        onChange={
                                                            handleFieldsChange
                                                        }
                                                    />
                                                    <p>{field}</p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* navigation button */}
                    <div className="flex justify-end text-center">
                        {steps[currentStep - 1].stepNo === 2 && (
                            <button
                                onClick={() => setCurrentStep(1)}
                                className="mt-5 text-sm"
                            >
                                Retour
                            </button>
                        )}
                        <StepperControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ProductNew;
