import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useNavigate, useParams } from 'react-router-dom';
import GoBack from 'components/actions/GoBack';
import {
    MdDoneOutline,
    MdOutlinePayment,
    MdOutlineRocketLaunch,
} from 'react-icons/md';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { getImage } from 'variables/images';
import OrderStatus from 'views/admin/main/ecommerce/orderDetails/components/OrderStatus';

const SellerOfferPage = () => {
    const [user, setUser] = useState<any>(null);
    const [sheetUrl, setSheetUrl] = useState(null);
    const [offer, setOffer] = useState<any>({});
    const nav = useNavigate();
    const params = useParams();

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                if (res.data && res.data.type !== 'seller') {
                    window.location.href = '/admin/my-request';
                }
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const getOffer = (id: string) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/${id}`, config)
            .then((res) => {
                setOffer(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
                //window.location.href = '/auth/sign-in';
            });
    };

    const initSheetUrl = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/seller-sheet/${params.id}`, config)
            .then((res) => {
                setSheetUrl(res.data.url);
            })
            .catch((err) => {
                console.log('url error', err);
            });
    };

    const areSellerInvoiceInfoValid = () => {
        return user?.siren && user?.company && user?.vatNumber && user?.address;
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';
        getOffer(params.id);
        initUser();
        initSheetUrl();
        // eslint-disable-next-line
    }, []);

    const InfoBox = (props: {
        icon?: any;
        title?: string;
        description?: string;
    }) => {
        const { icon, title, description } = props;
        return (
            <div className="w-full rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
                <div className="grid grid-cols-12 gap-0">
                    <div className="col-span-1 text-gray-900">{icon}</div>
                    <h4 className="col-span-10 text-lg font-bold text-gray-900 dark:text-white">
                        {title}
                    </h4>
                </div>
                <p className="mt-[12px] pr-2 text-sm text-gray-700 dark:text-white">
                    {description}
                </p>
            </div>
        );
    };

    return (
        <>
            <GoBack />
            <div className="mt-3 h-full w-full gap-2 rounded-[20px]">
                <Card
                    extra={
                        'relative w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                    }
                >
                    {offer?.invoiceIndex > 0 && areSellerInvoiceInfoValid() && (
                        <div
                            className="absolute right-4 top-4"
                            title="Générer la facture de vente"
                            onClick={() =>
                                window.open(
                                    `${CONFIG.apiUrl}/offer/${
                                        offer?._id
                                    }/invoice?token=${encodeURI(
                                        localStorage.getItem('token')
                                    )}`,
                                    '_blank'
                                )
                            }
                        >
                            <FaFileInvoiceDollar
                                size={25}
                                className="text-leadrs hover:cursor-pointer"
                            />
                        </div>
                    )}
                    {offer?.invoiceIndex > 0 &&
                        !areSellerInvoiceInfoValid() && (
                            <div className="absolute right-4 top-4">
                                <p
                                    className="text-sm text-leadrs underline hover:cursor-pointer"
                                    onClick={() => nav('/admin/settings')}
                                >
                                    Complétez vos informations pour générer la
                                    facture de vente
                                </p>
                            </div>
                        )}
                    {/* Header */}
                    <div className="mt-1 flex gap-10">
                        <div className="hidden lg:block">
                            {getImage(offer?.industry, offer?.subIndustry) ? (
                                <img
                                    src={getImage(
                                        offer?.industry,
                                        offer?.subIndustry
                                    )}
                                    className="mb-1 max-h-[500px] w-full rounded-xl"
                                    alt={`${offer?.industry} ${offer?.subIndustry}`}
                                />
                            ) : (
                                <div className="flex h-[500px] w-[500px] items-center justify-center rounded-xl bg-gray-500">
                                    <p>
                                        {offer?.industry} - {offer?.subIndustry}
                                    </p>
                                </div>
                            )}
                            <div className="mt-4">
                                <OrderStatus offer={offer} />
                            </div>
                        </div>
                        <div>
                            <p className="mt-5 text-xl text-navy-700 dark:text-white">
                                {offer?.subIndustry}
                            </p>
                            <h1 className="mt-2 text-2xl font-bold">
                                {offer?.industry}
                            </h1>
                            <p className="mb-5 text-sm font-bold">
                                #{offer?.offerNumber}
                            </p>
                            <div className="mb-5 mt-5 h-px w-full bg-gray-500"></div>
                            {offer?.status === 'done' && (
                                <InfoBox
                                    icon={<MdDoneOutline size={30} />}
                                    title={'Mission terminée'}
                                    description={`Vous avez généré ${
                                        offer?.leadsGenerated
                                    } leads (${
                                        offer?.leadsGenerated *
                                        offer?.sellerPrice.toFixed(2)
                                    }€ HT) . Profitez de cette traction et cherchez un autre acheteur avec le même besoin`}
                                />
                            )}
                            {offer?.status === 'running' && (
                                <InfoBox
                                    icon={<MdOutlineRocketLaunch size={30} />}
                                    title={'Mission en cours'}
                                    description={`Vous avez déjà généré ${
                                        offer?.leadsGenerated *
                                        offer?.sellerPrice.toFixed(2)
                                    }€ (${
                                        offer?.leadsGenerated
                                    } leads) leads pour cette mission. Le payement est effectué chaque semaine sur votre compte bancaire.`}
                                />
                            )}
                            {offer?.status === 'waiting_payment' && (
                                <InfoBox
                                    icon={<MdOutlinePayment size={30} />}
                                    title={'En attente de paiement'}
                                    description={`Vous pourrez commencer votre campagne une fois la commande payée par
                                        l'acheteur. En attendant, si un lead est généré, nous nous assurons de
                                        vous le rediriger par mail.`}
                                />
                            )}
                            {!user?.isKYCVerified && (
                                <InfoBox
                                    icon={<MdOutlinePayment size={30} />}
                                    title={'Coordonées bancaires manquantes'}
                                    description={`Avant de vous positionner sur une offre, vous devez renseigner vos coordonées bancaires pour recevoir vos paiements.`}
                                />
                            )}
                            {!user?.isValidated && (
                                <p className="text-red-400">
                                    Nous sommes en train de vérifier votre
                                    compte. Vous ne pouvez pas encore postuler à
                                    une offre.
                                </p>
                            )}
                            {offer?.status === 'on_market' &&
                                user?.isKYCVerified &&
                                user?.isValidated && (
                                    <div className="rounded-2xl bg-lightPrimary px-3 py-3 ">
                                        <p className="ml-2 text-sm font-medium">
                                            Votre offre est disponible sur la
                                            marketplace pour{' '}
                                            {offer?.sellerPrice}€ HT par lead.
                                        </p>
                                    </div>
                                )}
                            {sheetUrl && (
                                <div className="mt-5">
                                    <a
                                        href={sheetUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-leadrs underline"
                                    >
                                        Ouvrir mon Google Sheet
                                    </a>
                                </div>
                            )}
                            <div className="mt-5 flex gap-10">
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-1 text-sm font-bold">
                                        Quantité
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.weeklyQuantity} leads
                                    </p>
                                </div>
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-1 text-sm font-bold">
                                        Canaux d'acquisition
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.channels?.join(', ')}
                                    </p>
                                </div>
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-1 text-sm font-bold">
                                        Besoin
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.type}
                                    </p>
                                </div>
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-1 text-sm font-bold">
                                        Site web
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.websiteInfo}
                                    </p>
                                </div>
                            </div>
                            <div className="mb-1 mt-3 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Champs de données
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.fields?.join(', ')}
                                </p>
                            </div>
                            <div className="mt-5 flex gap-10">
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-1 text-sm font-bold">
                                        Description
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.description}
                                    </p>
                                </div>
                                <div className="mb-1 items-center gap-5">
                                    <p className="mb-2 text-sm font-bold">
                                        {' '}
                                        Départements:{' '}
                                    </p>
                                    <p className="mb-2 text-sm">
                                        {offer?.regions?.join(', ')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default SellerOfferPage;
