import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Portal } from '@chakra-ui/portal';
import Navbar from 'components/navbar';
import Footer from 'components/footer/Footer';
import routes from 'routes';
import routesBuyer from 'routesBuyer';
import Sidebar from 'components/sidebar';

export default function Admin(props: { [x: string]: any }) {
    const { ...rest } = props;
    const location = useLocation();
    const r =
        localStorage.getItem('user-type') === 'buyer' ? routesBuyer : routes;
    const [open, setOpen] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');
    React.useEffect(() => {
        window.addEventListener('resize', () =>
            window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
        );
    }, []);
    React.useEffect(() => {
        getActiveRoute(r);
        // eslint-disable-next-line
    }, [location.pathname]);
    // functions for changing the states from components
    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    setCurrentRoute(routes[i].name);
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((prop, key) => {
            if (prop.layout === '/admin' || prop.layout === '') {
                return (
                    <Route
                        path={`${prop.path}`}
                        element={prop.component}
                        key={key}
                    />
                );
            }
            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            return null;
        });
    };

    const displaySidebar = () => {
        var lastSlashIndex = window.location.href.lastIndexOf('/');
        var page = window.location.href.substring(lastSlashIndex + 1);
        return !['integrate', 'new-offer'].includes(page);
    };

    document.documentElement.dir = 'ltr';
    return (
        <div className="flex h-full w-full">
            {/* Navbar & Main Content */}
            {displaySidebar() && (
                <Sidebar open={open} onClose={() => setOpen(false)} />
            )}
            {/* Navbar & Main Content */}
            {/* Navbar & Main Content */}
            <div className="h-full w-full font-dm dark:bg-navy-900">
                {/* Main Content */}
                <main
                    className={`mx-10 flex-none transition-all dark:bg-navy-900 ${
                        displaySidebar() && 'sm:ml-[313px]'
                    }`}
                >
                    {/* Routes */}
                    <div>
                        <Portal>
                            <Navbar
                                onOpenSidenav={() => setOpen(!open)}
                                brandText={currentRoute}
                                secondary={getActiveNavbar(r)}
                                {...rest}
                            />
                        </Portal>
                        <div className="mx-auto min-h-screen p-2 !pt-[40px] md:p-2">
                            <Routes>
                                {getRoutes(r)}
                                <Route
                                    path="/"
                                    element={
                                        <Navigate
                                            to="/admin/dashboard"
                                            replace
                                        />
                                    }
                                />
                            </Routes>
                        </div>
                        <div className="p-3">
                            <Footer />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
