const Footer = () => {
    return (
        <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
            <p className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
                <span className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
                    ©{new Date().getFullYear()} Leadrs.co. All Rights Reserved.
                </span>
            </p>
            <div>
                <ul className="ml-10 flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-7">
                    <li>
                        <a
                            target="blank"
                            href="https://go.crisp.chat/chat/embed/?website_id=d7156788-8d6d-40b5-ba31-f28c6b9dd7b0"
                            className="text-base text-gray-600 hover:text-gray-600"
                        >
                            Support
                        </a>
                    </li>
                    <li>
                        <a
                            target="blank"
                            href="https://www.leadrs.co/"
                            className="text-base text-gray-600 hover:text-gray-600"
                        >
                            Mentions légales
                        </a>
                    </li>
                    <li>
                        <a
                            target="blank"
                            href="https://www.leadrs.co/"
                            className="text-base text-gray-600 hover:text-gray-600"
                        >
                            Politique de confidentialité
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
