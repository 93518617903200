import SolarImg from 'assets/offers/solar.png';
import HeatImg from 'assets/offers/heat.jpeg';
import InvestImg from 'assets/offers/invest.jpg';
import formationImg from 'assets/offers/formation.jpg';
import AssuranceImg from 'assets/offers/assurance.jpg';
import IteImg from 'assets/offers/ite.jpg';
import HabitationImg from 'assets/offers/habitation.jpg';
import AssuranceVieImg from 'assets/offers/assurance-vie.jpg';
import AnimauxImg from 'assets/offers/animaux.jpg';
import AssuranceAutoImg from 'assets/offers/assurance-auto.jpg';
import MutuelleObsImg from 'assets/offers/obsèques.jpg';
import VoyageImg from 'assets/offers/voyage.jpg';
import AssuranceScolaireImg from 'assets/offers/assurance-scolaire.jpg';
import AssuranceRespImg from 'assets/offers/rc.jpg';
import AssuranceEmprunteurImg from 'assets/offers/assurance-emprunteur.jpg';
import AssuranceSenior from 'assets/offers/assurance-senior.jpg';
import MutuelSenior from 'assets/offers/mutuel-senior.jpg';
import PrevoyanceImg from 'assets/offers/prevoyance.jpg';
import RechargeImg from 'assets/offers/recharge.jpg';
import DoucheImg from 'assets/offers/douche.jpg';
import EscalierImg from 'assets/offers/escalier.jpg';
import AuditifImg from 'assets/offers/auditif.jpg';
import IsolationExterneImg from 'assets/offers/isolation-externe.jpg';
import PiscineImg from 'assets/offers/piscine.jpg';

export const getImage = (industry: string, subIndustry: string) => {
    if (subIndustry === 'Assurance habitation') return HabitationImg;
    if (subIndustry === 'Assurance vie') return AssuranceVieImg;
    if (subIndustry === 'Assurance animaux') return AnimauxImg;
    if (subIndustry === 'Assurance automobile') return AssuranceAutoImg;
    if (subIndustry === 'Mutuelle obsèques') return MutuelleObsImg;
    if (subIndustry === 'Assurance voyage') return VoyageImg;
    if (subIndustry === 'Assurance scolaire') return AssuranceScolaireImg;
    if (subIndustry === 'Assurance responsabilité civile')
        return AssuranceRespImg;
    if (subIndustry === 'Assurance emprunteur') return AssuranceEmprunteurImg;
    if (subIndustry === 'Assurance santé senior') return AssuranceSenior;
    if (subIndustry === 'Mutuelle Santé Senior') return MutuelSenior;
    if (subIndustry === 'Prévoyance') return PrevoyanceImg;
    if (subIndustry === 'Borne de recharge') return RechargeImg;
    if (subIndustry === 'Douches sécurisées senior') return DoucheImg;
    if (subIndustry === 'Montes-escaliers') return EscalierImg;
    if (subIndustry === 'Appareils auditifs') return AuditifImg;
    if (subIndustry === 'Isolation Thermique Extérieure')
        return IsolationExterneImg;

    if (industry === 'Formation') return formationImg;
    if (subIndustry?.includes('Rénovation') || subIndustry === 'ITE')
        return IteImg;
    if (industry === 'Assurance') return AssuranceImg;
    if (industry === 'Investissement') return InvestImg;
    if (subIndustry === 'Panneaux solaire') return SolarImg;
    if (subIndustry === 'Panneaux solaires') return SolarImg;
    if (subIndustry === 'Piscine') return PiscineImg;
    if (subIndustry === 'Pompe à chaleur') return HeatImg;
};
