// Assets
import Card from 'components/card';
import DashCurveDown from 'components/icons/DashCurveDown';
import DashCurveUp from 'components/icons/DashCurveUp';

import { MdAddLink, MdCheckCircle, MdOutgoingMail } from 'react-icons/md';

const Earn = () => {
    return (
        <Card
            extra={
                'w-full pb-[80px] pt-[30px] px-[29px] border bg-white shadow-md rounded-xl h-fit xl:h-[390px]'
            }
        >
            {/* Header */}
            <div className="mt-1">
                <p className="text-lg font-semibold text-navy-700 dark:text-white">
                    Comment envoyer vos leads directement sur Leadrs ?
                </p>
                <p className="mt-4 text-base text-gray-600">
                    Connectez votre site, quel que soit votre CMS. Une fois
                    l'intégration validée, vous pourrez commencer à vendre vos
                    leads sur la plateforme
                </p>
            </div>

            <div className="relative mt-[40px] flex w-full flex-col justify-center gap-[50px] md:flex-row">
                <div
                    className={`absolute left-[150px] hidden text-leadrs dark:text-white md:block lg:left-[230px] xl:left-[200px] 2xl:left-[270px] 3xl:left-[180px] 4xl:left-[270px]`}
                >
                    <DashCurveUp />
                </div>
                <div className="absolute right-[140px] top-[60px] hidden text-leadrs dark:text-white md:block lg:right-[230px] xl:right-[200px] 2xl:right-[260px] 3xl:right-[170px] 4xl:right-[250px]">
                    <DashCurveDown />
                </div>

                {/* icons */}
                <div className="flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-leadrs shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
                        <MdAddLink />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="text-md mt-[18px] font-bold text-navy-700 dark:text-white">
                            Intégration
                        </p>
                        <p className="mt-3 text-center text-base text-gray-600">
                            Intégration en quelques clics avec typeform, Google
                            Sheet, zapier, webflow ou par API
                        </p>
                    </div>
                </div>
                {/* icons2 */}
                <div className="mr-[14px] flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-leadrs shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
                        <MdOutgoingMail />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="text-md mt-[18px] font-bold text-navy-700 dark:text-white">
                            Tester
                        </p>
                        <p className="mt-3 text-center text-base text-gray-600">
                            Nous vérifions que l'intégration fonctionne
                            correctement.
                        </p>
                    </div>
                </div>
                {/* icons3 */}
                <div className="flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-leadrs shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
                        <MdCheckCircle />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="text-md mt-[18px] text-center font-bold text-navy-700 dark:text-white">
                            Vérification
                        </p>
                        <p className="mt-3 text-center text-base text-gray-600">
                            Une fois l'intégration vérifiée, nous vérifions
                            votre compte.
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default Earn;
