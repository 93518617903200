import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import {
    MdEuro,
    MdOutlinePeopleOutline,
    MdOutlineShoppingBasket,
} from 'react-icons/md';
import { TbPigMoney } from 'react-icons/tb';
import MiniStatistics from 'components/card/MiniStatistics';
import ChartBar3Period from '../dashboard/components/ChartBar3Period';

const SellerReporting = () => {
    const [user, setUser] = useState(null);
    const [kpis, setKpis] = useState(null);
    const [isURLLoading, setIsURLLoading] = useState(false);

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                if (res.data && res.data.type !== 'seller') {
                    window.location.href = '/admin/my-request';
                }
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const initSellerKPI = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/seller-kpi`, config)
            .then((res) => {
                setKpis(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const startKYCProcess = () => {
        setIsURLLoading(true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/stripe/kyc`, config)
            .then((res) => {
                console.log(res.data);
                window.location.href = res.data.url;
            })
            .catch((err) => {
                console.log('Impossible to prepare KYC process', err);
            });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';
        initUser();
        initSellerKPI();
    }, []);

    const ReportingComingSoon = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Reporting 📁
                    </p>
                    <p className="text-md mt-2 text-gray-600">
                        L’aperçu complet de vos ventes, de vos paiements et du
                        solde restant à vendre.
                    </p>
                </div>
            </Card>
        );
    };

    return (
        <>
            {user && user.isKYCVerified === false && (
                <div
                    className="relative mb-3 rounded-[15px] border bg-red-100 px-4 py-3 text-yellow-700"
                    role="alert"
                >
                    <span className="block sm:inline">Vous devez </span>
                    <span
                        className="block cursor-pointer text-blue-600 underline visited:text-purple-600 hover:text-blue-800 sm:inline"
                        onClick={startKYCProcess}
                    >
                        ajouter vos coordonnées bancaires
                    </span>
                    <span className="block sm:inline">
                        {' '}
                        pour pouvoir commencer à vendre. (Utilisez le mail par
                        défaut uniquement)
                    </span>
                    {isURLLoading && (
                        <svg
                            aria-hidden="true"
                            className="ml-2 inline h-4 w-4 animate-spin fill-yellow-700 text-gray-200 dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    )}
                </div>
            )}
            <ReportingComingSoon />
            <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
                <div className="h-full w-full rounded-[20px]">
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Statistics */}
                        <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                            <div id="step-one">
                                <MiniStatistics
                                    name="Leads générés"
                                    value={kpis ? `${kpis?.leadsCount}` : '-'}
                                    icon={
                                        <MdOutlinePeopleOutline color="#7464fc" />
                                    }
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Offres crées"
                                    value={kpis ? `${kpis.createdOffers}` : '-'}
                                    icon={
                                        <MdOutlineShoppingBasket color="#7464fc" />
                                    }
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Offre rejoins"
                                    value={
                                        kpis ? `${kpis?.acceptedOffers}` : '-'
                                    }
                                    icon={<TbPigMoney color="#7464fc" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Prix moyen / lead"
                                    value={
                                        kpis
                                            ? `${kpis.averagePricePerOffer} €`
                                            : '-'
                                    }
                                    icon={<MdEuro color="#7464fc" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                        </div>
                        {/* Filter */}
                        {/* Main data */}
                        <div className="mt-5 grid w-full grid-cols-8 gap-5">
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                                    <ChartBar3Period
                                        title={'Vos offres'}
                                        data={kpis?.createdOrderSparklineData}
                                        calculateCumulativeSum={true}
                                    />
                                    <ChartBar3Period
                                        title={'Leads générés'}
                                        data={kpis?.leadGeneratedSparklineData}
                                        calculateCumulativeSum={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SellerReporting;
