import Card from 'components/card';
import { ChannelIcon } from 'components/icons';

const SellerBenchMarkCard = (props: {
    image: string;
    title: string;
    author: string;
    download?: string;
    price: string | number;
    weeklyQuantity?: string | number;
    extra?: string;
    status?: string;
    buttonDisabled?: boolean;
    isKYCVerified?: boolean;
    isValidated?: boolean;
    isArchived?: boolean;
    channels?: string[];
    onClick?: () => void;
}) => {
    const {
        title,
        author,
        image,
        extra,
        isArchived,
        weeklyQuantity,
        onClick,
        channels,
        price,
    } = props;
    return (
        <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] border-2 cursor-pointer hover:shadow-xl bg-white ${extra}`}
            onClick={onClick}
        >
            <div className="h-full w-full">
                <div className="relative w-full">
                    <img
                        src={image}
                        className="mb-3 max-h-[130px] w-full rounded-xl 3xl:h-full 3xl:w-full"
                        alt=""
                    />
                </div>

                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2 w-full">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {' '}
                            {title}{' '}
                        </p>
                        <div className="flex w-full justify-between">
                            <p className="text-sm font-medium text-gray-600 md:mt-2">
                                {author}
                            </p>
                            <p className="text-sm font-bold text-leadrs dark:text-white md:mt-2">
                                {weeklyQuantity} leads
                            </p>
                        </div>
                        <div>
                            <div className="mt-3 flex gap-2">
                                {channels?.map((channel: string) => (
                                    <div className="text-leadrs">
                                        <ChannelIcon channel={channel} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="absolute bottom-2 right-2">
                            <p className="text-xs font-bold text-navy-700">
                                {price}€ par lead
                            </p>
                        </div>
                        {isArchived && (
                            <p className="mt-2 font-bold text-green-500">
                                Mission terminée
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default SellerBenchMarkCard;
