import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/hooks';
import SuccessModal from '../myOffer/components/SuccessModal';

const MyLeads = () => {
    const [user, setUser] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [leads, setLeads] = useState([]);
    const nav = useNavigate();

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                if (res.data && res.data.type !== 'seller') {
                    window.location.href = '/admin/my-request';
                }
                console.log(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const initMyLeads = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/lead`, config)
            .then((res) => {
                setLeads(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
                window.location.href = '/auth/sign-in';
            });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';

        const urlParams = new URLSearchParams(window.location.search);
        const created = urlParams.get('created');
        if (created === 'true' && !isOpen) {
            onOpen();
        }

        initMyLeads();
        initUser();
    }, []);

    function timeAgo(date: string) {
        const now: any = new Date();
        const givenDate: any = new Date(date);
        const diffInSeconds = Math.floor((now - givenDate) / 1000);

        const days = Math.floor(diffInSeconds / (3600 * 24));
        const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        if (days > 0) {
            return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
        } else if (hours > 0) {
            return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
        } else if (minutes > 0) {
            return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
            return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
        }
    }

    function isWithin30Minutes(date: Date | string): boolean {
        const start = new Date();
        const end = new Date(date);
        const diffInMs = Math.abs(end.getTime() - start.getTime());
        const diffInMinutes = diffInMs / (1000 * 60);
        return diffInMinutes <= 30;
    }

    const DiscoverCommandPage = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Mes leads 👤
                    </p>
                    <p className="mt-2 text-base text-gray-600">
                        Visualiser les leads que vous avez envoyé sur leadrs.{' '}
                        Vous pouvez également visualiser le score de chaque
                        lead.
                    </p>
                </div>
            </Card>
        );
    };

    return (
        <div className="h-full w-full rounded-[20px]">
            <SuccessModal isOpen={isOpen} onClose={onClose} />
            <DiscoverCommandPage />
            {leads.length === 0 ? (
                <div className="mt-10">
                    <p className="text-center text-lg text-gray-500">
                        Aucun lead trouvé
                    </p>
                </div>
            ) : (
                <table className="mt-10 w-full text-left text-sm text-gray-500 rtl:text-right">
                    <thead className="bg-gray-50 text-gray-700">
                        <tr>
                            <th scope="col" className="px-2 py-3">
                                # ID Mission
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Canal d'acquisition
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Numéro du lead
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Méthode
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Adresse email
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Notation leadrs
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Notation client
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {leads.map((item: any, index: number) => (
                            <tr className="border-b border-gray-200 bg-white text-gray-800 hover:bg-gray-100">
                                <td className="px-2 py-3">
                                    {/* Created 10 minutes ago ? Live ping */}

                                    <p className="my-auto flex">
                                        #{item.orderNumber}
                                    </p>
                                    <div className="my-auto flex gap-2">
                                        {isWithin30Minutes(item?.createdAt) && (
                                            <span className="relative my-auto flex h-2 w-2">
                                                <span className="bg-toola-500/50 absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
                                                <span className="bg-toola-500/50 relative inline-flex h-2 w-2 rounded-full"></span>
                                            </span>
                                        )}
                                        <p className="text-xs text-gray-700">
                                            {timeAgo(item.createdAt)}
                                        </p>
                                    </div>
                                </td>
                                <td className="max-w-[180px] px-2 py-3">
                                    {item.channels?.join(', ')}
                                </td>
                                <td className="px-2 py-3">
                                    {item.totalLeads > 1
                                        ? `${item.index}/${item.totalLeads}`
                                        : 'N/A'}
                                </td>
                                <td className="px-2 py-3">
                                    {item.source || 'N/A'}
                                </td>
                                <td className="px-2 py-3">
                                    {item.email || 'N/A'}
                                </td>
                                <td className="px-2 py-3">
                                    {item.leadrsRate === 0 && (
                                        <span className="rounded-full bg-red-200 px-2 py-1 text-xs text-red-700">
                                            {item.leadrsRate}/10
                                        </span>
                                    )}
                                    {item.leadrsRate >= 7 && (
                                        <span className="rounded-full bg-green-200 px-2 py-1 text-xs text-green-700">
                                            {item.leadrsRate}/10
                                        </span>
                                    )}
                                    {item.leadrsRate < 7 &&
                                        item.leadrsRate > 0 && (
                                            <span className="rounded-full bg-yellow-200 px-2 py-1 text-xs text-yellow-700">
                                                {item.leadrsRate}/10
                                            </span>
                                        )}
                                </td>
                                <td className="px-2 py-3">
                                    {item.customerRate === -1 && 'N/A'}
                                    {item.customerRate === 0 && (
                                        <span className="rounded-full bg-red-200 px-2 py-1 text-xs text-red-700">
                                            {item.customerRate}/10
                                        </span>
                                    )}
                                    {item.customerRate >= 7 && (
                                        <span className="rounded-full bg-green-200 px-2 py-1 text-xs text-green-700">
                                            {item.customerRate}/10
                                        </span>
                                    )}
                                    {item.customerRate < 7 &&
                                        item.customerRate > 0 && (
                                            <span className="rounded-full bg-yellow-200 px-2 py-1 text-xs text-yellow-700">
                                                {item.customerRate}/10
                                            </span>
                                        )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default MyLeads;
