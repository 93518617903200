import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useParams } from 'react-router-dom';
import GoBack from 'components/actions/GoBack';
import { getImage } from 'variables/images';

const SellerBenchMarkPage = () => {
    const [offer, setOffer] = useState<any>({});
    const params = useParams();

    const getOffer = (id: string) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/seller-offer/marketplace/${id}`, config)
            .then((res) => {
                setOffer(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';
        getOffer(params.id);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <GoBack />
            <div className="mt-3 h-full w-full gap-2 rounded-[20px]">
                <Card
                    extra={
                        'relative w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                    }
                >
                    {/* Header */}
                    <div className="mt-1 flex gap-10">
                        <div>
                            {getImage(offer?.industry, offer?.subIndustry) ? (
                                <img
                                    src={getImage(
                                        offer?.industry,
                                        offer?.subIndustry
                                    )}
                                    className="mb-1 max-h-[500px] w-full rounded-xl"
                                    alt={`${offer?.industry} ${offer?.subIndustry}`}
                                />
                            ) : (
                                <div className="flex h-[500px] w-[500px] items-center justify-center rounded-xl bg-gray-500">
                                    <p>
                                        {offer?.industry} - {offer?.subIndustry}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="mt-5 text-xl text-navy-700 dark:text-white">
                                {offer?.subIndustry}
                            </p>
                            <h1 className="mt-2 text-2xl font-bold">
                                {offer?.industry}
                            </h1>
                            <p className="mb-5 text-sm font-bold">
                                #{offer?.offerNumber}
                            </p>
                            <div className="mb-5 mt-5 h-px w-full bg-gray-500"></div>
                            <div className="mb-1 mt-5 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Quantité
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.weeklyQuantity} leads
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Champs de données
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.fields?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Canaux d'acquisition
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.channels?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">Besoin</p>
                                <p className="mb-2 text-sm">{offer?.type}</p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-2 text-sm font-bold">
                                    {' '}
                                    Départements:{' '}
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.regions?.join(', ')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default SellerBenchMarkPage;
