import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useParams } from 'react-router-dom';
import GoBack from 'components/actions/GoBack';
import {
    MdDisplaySettings,
    MdDoneOutline,
    MdOutlinePayment,
    MdOutlineRocketLaunch,
} from 'react-icons/md';
import { getImage } from 'variables/images';
import OrderStatus from 'views/admin/main/ecommerce/orderDetails/components/OrderStatus';

const BuyerOfferPage = () => {
    const [user, setUser] = useState<any>(null);
    const [offer, setOffer] = useState<any>({});
    const [sheetUrl, setSheetUrl] = useState(null);
    const params = useParams();

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                if (res.data && res.data.type !== 'buyer') {
                    window.location.href = '/admin/my-request';
                }
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const initSheetUrl = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/buyer-sheet/${params.id}`, config)
            .then((res) => {
                setSheetUrl(res.data.url);
            })
            .catch((err) => {
                console.log('url error', err);
            });
    };

    const getOffer = (id: string) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/${id}`, config)
            .then((res) => {
                setOffer(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';
        getOffer(params.id);
        initUser();
        initSheetUrl();
        // eslint-disable-next-line
    }, []);

    const InfoBox = (props: {
        icon?: any;
        title?: string;
        description?: string;
    }) => {
        const { icon, title, description } = props;
        return (
            <div className="w-full rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
                <div className="grid grid-cols-12 gap-0">
                    <div className="col-span-1 text-gray-900">{icon}</div>
                    <h4 className="col-span-10 text-lg font-bold text-gray-900 dark:text-white">
                        {title}
                    </h4>
                </div>
                <p className="mt-[12px] whitespace-pre-line pr-2 text-sm text-gray-700 dark:text-white">
                    {description}
                </p>
            </div>
        );
    };

    return (
        <div>
            <GoBack />
            <div className="mt-3 h-full w-full gap-2 rounded-[20px]">
                <Card
                    extra={
                        'relative w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                    }
                >
                    {/* Header */}
                    <div className="mt-1 gap-10">
                        <div className="hidden lg:block">
                            {getImage(offer?.industry, offer?.subIndustry) ? (
                                <img
                                    src={getImage(
                                        offer?.industry,
                                        offer?.subIndustry
                                    )}
                                    className="mb-1 max-h-[500px] w-full rounded-xl"
                                    alt={`${offer?.industry} ${offer?.subIndustry}`}
                                />
                            ) : (
                                <div className="flex h-[500px] w-[500px] items-center justify-center rounded-xl bg-gray-500">
                                    <p>
                                        {offer?.industry} - {offer?.subIndustry}
                                    </p>
                                </div>
                            )}
                            <OrderStatus offer={offer} />
                        </div>
                        <div>
                            <p className="mt-5 text-xl text-navy-700 dark:text-white">
                                {offer?.subIndustry}
                            </p>
                            <h1 className="mt-2 text-2xl font-bold">
                                {offer?.industry}
                            </h1>
                            <p className="mb-5 text-sm font-bold">
                                #{offer?.offerBuyerNumber}
                            </p>
                            <div className="mb-5 mt-5 h-px w-full bg-gray-500"></div>
                            {offer?.status === 'done' && (
                                <InfoBox
                                    icon={<MdDoneOutline size={30} />}
                                    title={'Mission terminée'}
                                    description={`Vous avez généré ${
                                        offer?.leadsGenerated
                                    } leads (${
                                        offer?.leadsGenerated *
                                        offer?.sellerPrice?.toFixed(2)
                                    }€ HT) . Profitez de cette traction et cherchez un autre acheteur avec le même besoin`}
                                />
                            )}
                            {offer?.status === 'waiting_subscription' && (
                                <div
                                    className="hover:cursor-pointer"
                                    onClick={() => {
                                        window.location.href =
                                            '/admin/subscribe';
                                    }}
                                >
                                    <InfoBox
                                        icon={<MdOutlinePayment size={30} />}
                                        title={'Abonnement nécessaire'}
                                        description={`Vous devez être abonné pour pouvoir publier cette offre.`}
                                    />
                                    <button
                                        className="linear mx-auto mt-2 w-full rounded-xl border-2 border-leadrs px-5 py-2 text-sm font-medium text-leadrs transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5"
                                        onClick={() => {
                                            window.location.href =
                                                '/admin/subscribe';
                                        }}
                                    >
                                        S'abonner
                                    </button>
                                </div>
                            )}
                            {offer?.status === 'on_market' &&
                                user &&
                                user.isValidated && (
                                    <InfoBox
                                        icon={<MdDisplaySettings size={30} />}
                                        title={'Paramétrage de la campagne'}
                                        description={`Votre campagne est en cours de paramétrage. Vous receverez un mail avec un lien de paiement une fois que tout sera prêt !`}
                                    />
                                )}
                            {offer?.status === 'on_market' &&
                                user &&
                                !user.isValidated && (
                                    <InfoBox
                                        icon={<MdDisplaySettings size={30} />}
                                        title={'En attente de validation'}
                                        description={`Votre compte doit être validé par un administrateur, votre offre sera publiée une fois que ce sera fait.`}
                                    />
                                )}
                            {offer?.status === 'waiting_payment' && (
                                <InfoBox
                                    icon={<MdOutlinePayment size={30} />}
                                    title={'En attente de paiement'}
                                    description={`Vous pouvez commander ${
                                        offer.weeklyQuantity
                                    } leads à ${offer.buyerPrice?.toFixed(
                                        2
                                    )}€ HT l'unité. Votre campagne commence une fois la commande payé.\n Votre argent reste sous séquestre jusqu'à la livraison complète.`}
                                />
                            )}
                            {offer?.status === 'waiting_payment' && (
                                <button
                                    className="data-rewardful linear mt-2 rounded-xl border-2 border-blue-300 px-5 py-2 text-sm font-medium text-blue-400 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5"
                                    onClick={() => {
                                        window.location.href = `${CONFIG.apiUrl}/stripe/checkout/${offer._id}/${user._id}`;
                                    }}
                                >
                                    Payer{' '}
                                    {offer.buyerPrice?.toFixed(2) *
                                        offer.weeklyQuantity}
                                    € HT
                                </button>
                            )}
                            {offer?.status === 'running' && (
                                <>
                                    <p className="mb-3 ml-2 text-gray-700">
                                        💡 La totalité de vos leads arriveront
                                        d'ici à ~ 1 mois.
                                    </p>
                                    <InfoBox
                                        icon={
                                            <MdOutlineRocketLaunch size={30} />
                                        }
                                        title={'Mission en cours'}
                                        description={`Vous avez déjà reçu ${
                                            offer.leadsGenerated
                                        } leads pour cette mission. Cela représente ${
                                            offer.leadsGenerated *
                                            offer.buyerPrice?.toFixed(2)
                                        }€.`}
                                    />
                                </>
                            )}
                            {sheetUrl && (
                                <div className="mt-5">
                                    <a
                                        href={sheetUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-leadrs underline"
                                    >
                                        Ouvrir mon Google Sheet
                                    </a>
                                </div>
                            )}
                            <div className="mb-1 mt-5 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Quantité
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.weeklyQuantity} leads
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Canaux d'acquisition
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.channels?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Champs de données
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.fields?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">Besoin</p>
                                <p className="mb-2 text-sm">{offer?.type}</p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-2 text-sm font-bold">
                                    {' '}
                                    Départements:{' '}
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.regions?.join(', ')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default BuyerOfferPage;
