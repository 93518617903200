export const defaultPricing = [
    '20-25',
    '25-30',
    '30-35',
    '35-40',
    '40-45',
    '45-50',
    '50-55',
    '55-60',
    '60-65',
    '65-70',
    '70-75',
    '75-80',
    '80-85',
    '85-90',
    '90-95',
    '95-100',
];

export const industriesPricing: any = {
    Assurance: {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Situation familiale',
            'Profession',
            'Revenus annuels',
            'Antécédents médicaux',
            'Type de couverture souhaitée',
        ],
    },
    Banque: {
        pricing: defaultPricing,
        fields: [
            'Prêts personnels',
            'Prêts étudiants',
            'Investissements et placements',
            'Prêts hypothécaires',
            'Gestion de patrimoine',
            'Conseils en investissement',
            'Défiscalisation',
            'Trading en ligne Bourse',
            'Trading en ligne Crypto',
            'Conseils boursiers',
            'Conseil en gestion de patrimoine',
        ],
    },
    Santé: {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Antécédents médicaux',
            'Objectifs de santé (perte de poids, bien-être mental, remise en forme, etc.)',
            'Habitudes alimentaires',
            'Activité physique',
            'Localisation',
        ],
    },
    Voyages: {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Type de voyage recherché',
            'Destination préférée',
            'Budget',
            'Durée du séjour',
            'Nombre de personnes',
            'Mode de transport préféré',
        ],
    },
    Formation: {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Niveau d’études actuel',
            'Situation professionnelle',
            'Domaine d’intérêt pour la formation',
            'Objectif de la formation (reconversion, montée en compétences, etc.)',
            'Temps disponible pour la formation',
            'Budget alloué',
        ],
    },
    'Services Juridique': {
        pricing: defaultPricing,
        fields: [
            'Type de service recherché (droit de la famille, immobilier, entreprise, etc.)',
            'Problématique juridique spécifique',
            'Localisation',
            'Urgence du besoin',
            'Revenus',
        ],
    },
    'Services de transport et mobilité': {
        pricing: defaultPricing,
        fields: [
            'Type de transport recherché (voiture, transport public, vélo, etc.)',
            'Fréquence d’utilisation',
            'Localisation',
            'Budget',
            'Type de service (location, abonnement, etc.)',
        ],
    },
    'Services de déménagement et logistique': {
        pricing: defaultPricing,
        fields: [
            'Adresse actuelle',
            'Nouvelle adresse',
            'Nombre d’objets à déménager',
            'Date du déménagement',
            'Besoins supplémentaires (emballage, démontage, etc.)',
        ],
    },
    'Voyance et ésotérisme': {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Type de consultation recherchée (tarot, astrologie, voyance en ligne, etc.)',
            'Problématique ou question spécifique',
            'Signe Astro',
        ],
    },
    'Leads liés aux seniors': {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Situation de vie (retraité, en activité, etc.)',
            'Besoins spécifiques (domicile, santé, loisirs, etc.)',
            'Revenus mensuels',
            'Type de services recherchés (aide à domicile, loisirs, etc.)',
            'Budget alloué',
        ],
    },
    'Leads urgence habitat': {
        pricing: defaultPricing,
        fields: [
            'Type de problème à résoudre (plomberie, électricité, toiture, etc.)',
            'Type de bien (maison, appartement)',
            'Localisation',
            "Urgence de l'intervention",
            'Disponibilité du client',
        ],
    },
    Rénovation: {
        pricing: defaultPricing,
        steps: 5,
        fields: [
            'Age',
            'Réside dans une maison',
            'Réside dans un appartement',
            'Superficie',
            'Budget estimé',
            'Localisation',
            'Propriétaire',
            'Montant de facture électricité',
        ],
    },
    Immobilier: {
        pricing: defaultPricing,
        fields: [
            'Investissement immobilier (LMNP, SCPI)',
            'Immobilier commercial',
            'Immobilier neuf',
            'Crédit immobilier',
            'Renégociation crédit immobilier',
        ],
    },
    Investissement: {
        pricing: defaultPricing,
        fields: [
            'Âge',
            'Revenus annuels',
            'Situation financière',
            "Type d'investissement recherché",
            "Objectifs d'investissement",
            'Localisation',
            'Montant des impots',
            'Epargne à investir',
            'Aversion au risque',
        ],
    },
};

export const industries: any = {
    Rénovation: [
        { name: 'Pompe à chaleur', sub: [] },
        { name: 'Panneaux solaires', sub: [] },
        { name: 'Isolation Thermique Extérieure', sub: [] },
        { name: 'Isolation Thermique Intérieure', sub: [] },
        { name: 'Alarme', sub: [] },
        { name: 'Piscine', sub: [] },
        { name: 'Cuisine', sub: [] },
        { name: 'Radiateur', sub: [] },
        { name: 'Chaudière à condensation', sub: [] },
        { name: 'Fenêtres', sub: [] },
        { name: 'Poêle à granulés', sub: [] },
        { name: 'Borne de recharge', sub: [] },
        { name: 'Toiture', sub: [] },
        { name: 'Véranda', sub: [] },
        { name: 'Volets roulants', sub: [] },
        { name: 'Energie', sub: [] },
        { name: 'Autre', sub: [] },
    ],
    Formation: [
        {
            name: 'Langues',
            sub: [
                'Anglais',
                'Espagnol',
                'Italien',
                'Chinois',
                'Japonais',
                'Russe',
                'Portugais',
                'Allemand',
            ],
        },
        {
            name: 'Généraliste',
            sub: [
                'Excel',
                'Photoshop',
                'Illustrator',
                'WordPress',
                'SketchUp',
                'Création entreprise',
                'Bilan de compétences',
            ],
        },
        {
            name: 'Niche',
            sub: [
                'Prothésiste ongulaire',
                'VTC',
                'CACES conducteur d’engins',
                'Maquillage professionnel',
                'Pâtisserie',
                'Nutritionniste',
                'Electricien',
                'Plombier',
                'Réparation vélo',
                'Réparation smartphone',
                'Coach sportif',
            ],
        },
        { name: 'Cours en ligne et formations professionnelles', sub: [] },
        { name: 'E-learning et tutorat en ligne', sub: [] },
        { name: 'Services de coaching et de développement personnel', sub: [] },
        { name: 'Certification professionnelle', sub: [] },
        { name: 'Formation et soutien scolaire à domicile', sub: [] },
        { name: 'Autre', sub: [] },
    ],
    Assurance: [
        { name: 'Assurance habitation', sub: [] },
        { name: 'Assurance automobile', sub: [] },
        { name: 'Assurance vie', sub: [] },
        { name: 'Assurance animaux', sub: [] },
        { name: 'Mutuelle obsèques', sub: [] },
        { name: 'Assurance voyage', sub: [] },
        { name: 'Assurance scolaire', sub: [] },
        { name: 'Assurance responsabilité civile', sub: [] },
        { name: 'Assurance emprunteur', sub: [] },
        { name: 'Assurance santé senior', sub: [] },
        { name: 'Mutuelle Santé Senior', sub: [] },
        { name: 'Prévoyance', sub: [] },
        { name: 'Autre', sub: [] },
    ],
    Investissement: [
        { name: 'SCPI', sub: [] },
        { name: 'LMNP', sub: [] },
        { name: 'Pinel', sub: [] },
        { name: 'Résidence principale', sub: [] },
        { name: 'Parking', sub: [] },
        { name: 'Rachat de crédit', sub: [] },
        { name: 'Autre', sub: [] },
    ],
    Immobilier: [
        { name: 'Immobilier commercial', sub: [] },
        { name: 'Immobilier neuf', sub: [] },
        { name: 'Crédit immobilier', sub: [] },
        { name: 'Renégociation crédit immobilier', sub: [] },
    ],
    Banque: [
        { name: 'Prêts personnels', sub: [] },
        { name: 'Prêts étudiants', sub: [] },
        { name: 'Investissements et placements', sub: [] },
        { name: 'Prêts hypothécaires', sub: [] },
        { name: 'Gestion de patrimoine', sub: [] },
        { name: 'Conseils en investissement', sub: [] },
        { name: 'Défiscalisation', sub: [] },
        { name: 'Trading en ligne Bourse', sub: [] },
        { name: 'Trading en ligne Crypto', sub: [] },
        { name: 'Conseils boursiers', sub: [] },
        { name: 'Conseil en gestion de patrimoine', sub: [] },
    ],
    Santé: [
        { name: 'Coaching santé', sub: [] },
        { name: 'Abonnements à des salles de sport', sub: [] },
        { name: 'Coaching en ligne', sub: [] },
        { name: 'Services de bien-être mental', sub: [] },
    ],
    Voyages: [
        { name: 'Agences de voyage et séjours organisés', sub: [] },
        { name: 'Hôtels, locations de vacances, Airbnb', sub: [] },
        { name: 'Croisières et voyages de luxe', sub: [] },
        {
            name: 'Activités touristiques et loisirs (excursions, attractions)',
            sub: [],
        },
    ],
    'Services Juridique': [
        { name: 'Consultation juridique en ligne', sub: [] },
        {
            name: 'Services d’avocats (droit de la famille, droit du travail, etc.)',
            sub: [],
        },
        { name: "Assistance en cas d'accidents et de litiges", sub: [] },
        {
            name: 'Rédaction de documents légaux (contrats, testaments, etc.)',
            sub: [],
        },
    ],
    'Services de transport et mobilité': [
        { name: 'Location de voitures', sub: [] },
        { name: "Vente de véhicules (neufs, d'occasion)", sub: [] },
        {
            name: 'Leads liés à l’automobile (financement auto, leasing)',
            sub: [],
        },
        {
            name: 'Entretien de véhicules (lavage, vidange, etc.) à domicile',
            sub: [],
        },
    ],
    'Services de déménagement et logistique': [
        {
            name: 'Services de déménagement (nationaux et internationaux)',
            sub: [],
        },
        { name: 'Location de camions de déménagement', sub: [] },
        {
            name: 'Transport et stockage de meubles et objets personnels',
            sub: [],
        },
        { name: 'Services d’emballage et de déballage', sub: [] },
        {
            name: 'Transport de meubles lourds (pianos, objets fragiles)',
            sub: [],
        },
    ],
    'Voyance et ésotérisme': [
        {
            name: 'Consultations de voyance en ligne (tarot, astrologie, etc.)',
            sub: [],
        },
        { name: 'Médiums et coachs spirituels', sub: [] },
        { name: "Services de numérologie et d'astrologie", sub: [] },
        { name: 'Séances de développement personnel et spirituel', sub: [] },
    ],
    'Leads liés aux seniors': [
        { name: 'Appareils auditifs', sub: [] },
        { name: 'Douches sécurisées senior', sub: [] },
        { name: 'Montes-escaliers', sub: [] },
        { name: 'Assistance à la personne', sub: [] },
        { name: 'Services de ménage', sub: [] },
    ],
    'Leads urgence habitat': [
        { name: "Plomberie d'urgence", sub: [] },
        { name: 'Dépannage électrique', sub: [] },
        { name: 'Réparation de chaudière', sub: [] },
        { name: 'Dératisation et désinsectisation', sub: [] },
        { name: "Réparation d'appareils électroménagers", sub: [] },
        { name: 'Serrurerie', sub: [] },
        { name: 'Nettoyage après sinistre', sub: [] },
        { name: 'Entretien et réparation de toiture', sub: [] },
        { name: 'Entretien de jardin et paysagisme', sub: [] },
        { name: 'Réparation de vitres et menuiserie', sub: [] },
        { name: 'Assistance informatique et dépannage', sub: [] },
        { name: "Service de garde d'enfants à domicile", sub: [] },
        {
            name: 'Réparation de systèmes de climatisation et ventilation',
            sub: [],
        },
        { name: 'Débouchage de canalisations', sub: [] },
        {
            name: 'Vente et installation de systèmes de sécurité à domicile',
            sub: [],
        },
        { name: 'Vétérinaire à domicile', sub: [] },
        { name: 'Nettoyage de tapis et moquettes', sub: [] },
        { name: 'Réparation de smartphones et tablettes', sub: [] },
        { name: 'Installation et réparation de portes et fenêtres', sub: [] },
        { name: 'Assistance administrative et comptable à domicile', sub: [] },
    ],
    Autre: [{ name: 'Autre', sub: [] }],
};

export const frenchAreas = [
    'Toute la France',
    'Ain (01)',
    'Aisne (02)',
    'Allier (03)',
    'Alpes-de-Haute-Provence (04)',
    'Hautes-Alpes (05)',
    'Alpes-Maritimes (06)',
    'Ardèche (07)',
    'Ardennes (08)',
    'Ariège (09)',
    'Aube (10)',
    'Aude (11)',
    'Aveyron (12)',
    'Bouches-du-Rhône (13)',
    'Calvados (14)',
    'Cantal (15)',
    'Charente (16)',
    'Charente-Maritime (17)',
    'Cher (18)',
    'Corrèze (19)',
    'Corse-du-Sud (2A)',
    'Haute-Corse (2B)',
    "Côte-d'Or (21)",
    "Côtes-d'Armor (22)",
    'Creuse (23)',
    'Dordogne (24)',
    'Doubs (25)',
    'Drôme (26)',
    'Eure (27)',
    'Eure-et-Loir (28)',
    'Finistère (29)',
    'Gard (30)',
    'Haute-Garonne (31)',
    'Gers (32)',
    'Gironde (33)',
    'Hérault (34)',
    'Ille-et-Vilaine (35)',
    'Indre (36)',
    'Indre-et-Loire (37)',
    'Isère (38)',
    'Jura (39)',
    'Landes (40)',
    'Loir-et-Cher (41)',
    'Loire (42)',
    'Haute-Loire (43)',
    'Loire-Atlantique (44)',
    'Loiret (45)',
    'Lot (46)',
    'Lot-et-Garonne (47)',
    'Lozère (48)',
    'Maine-et-Loire (49)',
    'Manche (50)',
    'Marne (51)',
    'Haute-Marne (52)',
    'Mayenne (53)',
    'Meurthe-et-Moselle (54)',
    'Meuse (55)',
    'Morbihan (56)',
    'Moselle (57)',
    'Nièvre (58)',
    'Nord (59)',
    'Oise (60)',
    'Orne (61)',
    'Pas-de-Calais (62)',
    'Puy-de-Dôme (63)',
    'Pyrénées-Atlantiques (64)',
    'Hautes-Pyrénées (65)',
    'Pyrénées-Orientales (66)',
    'Bas-Rhin (67)',
    'Haut-Rhin (68)',
    'Rhône (69)',
    'Haute-Saône (70)',
    'Saône-et-Loire (71)',
    'Sarthe (72)',
    'Savoie (73)',
    'Haute-Savoie (74)',
    'Paris (75)',
    'Seine-Maritime (76)',
    'Seine-et-Marne (77)',
    'Yvelines (78)',
    'Deux-Sèvres (79)',
    'Somme (80)',
    'Tarn (81)',
    'Tarn-et-Garonne (82)',
    'Var (83)',
    'Vaucluse (84)',
    'Vendée (85)',
    'Vienne (86)',
    'Haute-Vienne (87)',
    'Vosges (88)',
    'Yonne (89)',
    'Territoire de Belfort (90)',
    'Essonne (91)',
    'Hauts-de-Seine (92)',
    'Seine-Saint-Denis (93)',
    'Val-de-Marne (94)',
    "Val-d'Oise (95)",
    'Guadeloupe (971)',
    'Martinique (972)',
    'Guyane (973)',
    'La Réunion (974)',
    'Mayotte (976)',
];

export const allChannels = [
    'Google Ads',
    'Bing Ads',
    'Taboola',
    'Facebook',
    'Instagram',
    'Twitter',
    'LinkedIn',
    'Pinterest',
    'SEO',
    'Emailing',
    'SMS',
    'Newsletter',
    'Display',
    'Tik Tok',
    'Robot I.A',
];
