export default function StepperControl(props: {
    steps: {
        stepNo: number;
        name: string;
        highlighted?: boolean;
        selected?: boolean;
        completed?: boolean;
    }[];
    currentStep: number;
    handleClick: any;
}) {
    const { steps, currentStep, handleClick } = props;
    return (
        <div className="mt-2 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
            <div />

            <button
                onClick={() => handleClick('next')}
                className="mt-2 cursor-pointer rounded-xl bg-leadrs px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
            >
                {currentStep === steps.length
                    ? 'Envoyer ma demande'
                    : 'Suivant'}
            </button>
        </div>
    );
}
