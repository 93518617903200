import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Card from 'components/card';
import { MdClose } from 'react-icons/md';

const SuccessModal = (props: { isOpen: any; onClose: any }) => {
    const { isOpen, onClose } = props;

    return (
        <div className="relative">
            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] border-none p-0 shadow-none focus:outline-none md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[750px] flex flex-col !z-[1004] h-[250px]">
                            <div className="absolute right-5 top-5">
                                <MdClose
                                    size={20}
                                    className="hover: cursor-pointer text-red-600 hover:text-red-700"
                                    onClick={onClose}
                                />
                            </div>
                            <p className="text-center text-xl font-bold">
                                Offre déposé 🎉
                            </p>
                            <p className="text-md mt-8 text-center">
                                Nous vous remercions d'avoir déposé une nouvelle
                                annonce de leads. Nous reviendrons vers vous
                                dans les plus brefs délais pour vérifier la
                                faisabilité de votre demande.
                            </p>
                            <p className="mr-3 mt-4 flex justify-center">
                                L’équipe Leadrs
                            </p>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
export default SuccessModal;
