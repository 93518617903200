import { MdEuro, MdOutlineShoppingBasket } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { TbMoneybag, TbPigMoney } from 'react-icons/tb';

import MiniStatistics from 'components/card/MiniStatistics';
import StockValue from './components/ProjectStatus';
import Card from 'components/card';
import AverageBasket from './components/averageBasket';
import OrdersLines from './components/OrdersLines';
import BenefitLines from './components/BenefitLines';
import TopCustomersMap from './components/TopCustomersMap';
import VolumeTable from './components/volumeTable';
import { CONFIG } from 'variables/config';
import axios from 'axios';
import ChartBar3Period from '../../seller/dashboard/components/ChartBar3Period';

const ReportingDashboard = () => {
    const [data] = useState<any>(null);
    const [allDates] = useState<any>([]);
    const [kpis, setKpis] = useState<any>(null);

    const initSellerKPI = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/buyer-kpi`, config)
            .then((res) => {
                setKpis(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    useEffect(() => {
        initSellerKPI();
    }, []);

    return (
        <>
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Analytics 📊
                    </p>
                    <p className="mt-2 text-base text-gray-600">
                        Accédez à des rapports détaillés et des statistiques
                        pour évaluer vos performances et ajuster vos actions
                    </p>
                </div>
            </Card>
            <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
                <div className="h-full w-full rounded-[20px]">
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Statistics */}
                        <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                            <div id="step-one">
                                <MiniStatistics
                                    name="Mes offres"
                                    value={kpis ? `${kpis.totalOffers}` : '-'}
                                    icon={
                                        <MdOutlineShoppingBasket color="#7464fc" />
                                    }
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Offres crées"
                                    value={kpis ? `${kpis.createdOffers}` : '-'}
                                    icon={<MdEuro color="#7464fc" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Offres rejoins"
                                    value={
                                        kpis ? `${kpis.acceptedOffers}` : '-'
                                    }
                                    icon={<TbMoneybag color="#7464fc" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Coût moyen / lead"
                                    value={
                                        kpis
                                            ? `${kpis.averagePricePerOffer} €`
                                            : '-'
                                    }
                                    icon={<TbPigMoney color="#7464fc" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                        </div>
                        {/* Filter */}
                        {/* Main data */}
                        <div className="mt-5 grid w-full grid-cols-8 gap-5">
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                                    <ChartBar3Period
                                        title={'Leads reçus'}
                                        data={kpis?.leadReceivedSparklineData}
                                        calculateCumulativeSum={true}
                                    />
                                    <ChartBar3Period
                                        title={'Mes offres'}
                                        data={kpis?.createdOrderSparklineData}
                                        calculateCumulativeSum={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportingDashboard;
