import Card from 'components/card';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export function transformMongoAggregateToApexChart(
    data: any,
    type: any = 'monthly',
    calculateCumulativeSum = true
) {
    data.sort((a: any, b: any) => {
        const [monthA, yearA] = a._id.split('-').map(Number);
        const [monthB, yearB] = b._id.split('-').map(Number);
        return yearA !== yearB ? yearA - yearB : monthA - monthB;
    });

    const aggregatedData: any[] = [];
    let cumulativeSum = 0;
    let values: any[] = [];
    let currentPeriod = '';

    data.forEach((item: any) => {
        const [month, year] = item._id.split('-').map(Number);
        let periodKey: any;

        switch (type) {
            case 'monthly':
                periodKey = `${month}-${year}`;
                break;
            case 'quarterly':
                periodKey = `Q${Math.ceil(month / 3)}-${year}`;
                break;
            case 'yearly':
                periodKey = `${year}`;
                break;
        }

        if (currentPeriod !== periodKey) {
            currentPeriod = periodKey;
            cumulativeSum = 0;
            values = [];
        }

        // cumulative sum
        cumulativeSum += item.sum;
        values.push(item.sum);

        const existingEntry = aggregatedData.find((d) => d._id === periodKey);
        if (existingEntry) {
            existingEntry.sum = cumulativeSum;
            existingEntry.values = values;
            existingEntry.average = cumulativeSum / values.length;
        } else {
            aggregatedData.push({
                _id: periodKey,
                sum: cumulativeSum,
                values,
                average: cumulativeSum / values.length,
            });
        }
    });

    return {
        series: [
            {
                name: 'Total',
                data: calculateCumulativeSum
                    ? aggregatedData.map((item: any) => item.sum)
                    : aggregatedData.map((item: any) => item.average),
            },
        ],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    tools: {
                        download: false,
                    },
                },
            },
            colors: ['#7464fc'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: aggregatedData.map((item: any) => {
                    const [period, year] = item._id.split('-');
                    switch (type) {
                        case 'monthly':
                            return `${period.padStart(2, '0')}/${year}`;
                        case 'quarterly':
                            return `${period} ${year}`;
                        case 'yearly':
                            return period;
                    }
                }),
            },
            yaxis: {
                labels: {
                    formatter: (val: any) => parseInt(val),
                },
            },
        },
    };
}

function ChartBar3Period(props: {
    title: string;
    data: any;
    calculateCumulativeSum: boolean;
}) {
    const { title, data, calculateCumulativeSum } = props;

    const [state, setState] = useState(null);
    const [period, setPeriod] = useState('monthly');

    useEffect(() => {
        if (!data || !data.length) return;
        setState(
            transformMongoAggregateToApexChart(
                data,
                period,
                calculateCumulativeSum
            )
        );
    }, [data, period]);

    return (
        <Card extra={'relative p-5 text-dm lg:h-full max-h-[320px] border'}>
            <h4 className="ml-px text-base font-bold text-navy-700 dark:text-white">
                {title}
            </h4>
            {data &&
                data.length > 1 &&
                state &&
                (() => {
                    const seriesLength = state.series[0].data.length;
                    const previous = state.series[0].data[seriesLength - 2];
                    const current = state.series[0].data[seriesLength - 1];
                    const growthRate = ((current - previous) / previous) * 100;
                    const isPositive = growthRate >= 0;
                    const periods: any = {
                        monthly: 'ce mois',
                        quarterly: 'ce trimestre',
                        yearly: 'cette année',
                    };

                    return (
                        <p
                            className={`mt-1 text-[13px] ${
                                isPositive ? 'text-leadrs' : 'text-red-500'
                            }`}
                        >
                            {isPositive && '+'}
                            {growthRate.toFixed(2)}% {periods[period]}
                        </p>
                    );
                })()}
            <div className="absolute right-4 top-4">
                <div className="mt-2 flex gap-4">
                    <p
                        className={`text-sm ${
                            period === 'monthly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('monthly')}
                    >
                        Mensuel
                    </p>
                    <p
                        className={`text-sm ${
                            period === 'quarterly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('quarterly')}
                    >
                        Trimestriel
                    </p>
                    <p
                        className={`text-sm ${
                            period === 'yearly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('yearly')}
                    >
                        Annuel
                    </p>
                </div>
            </div>
            {state ? (
                <div id="chart">
                    <ReactApexChart
                        options={state.options as any}
                        series={state.series}
                        type="bar"
                        height={250}
                    />
                </div>
            ) : (
                <div className="flex h-96 items-center justify-center">
                    <p className="text-lg text-gray-500">Chargement...</p>
                </div>
            )}
            <div id="html-dist"></div>
        </Card>
    );
}

export default ChartBar3Period;
