import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useParams } from 'react-router-dom';
import GoBack from 'components/actions/GoBack';
import {
    MdDoneOutline,
    MdOutlinePayment,
    MdOutlineRocketLaunch,
} from 'react-icons/md';
import { getImage } from 'variables/images';
import InputField from 'components/fields/InputField';

const SellerMarketPlacePage = () => {
    const [user, setUser] = useState<any>(null);
    const [offer, setOffer] = useState<any>({});
    const params = useParams();
    const [finalPrice, setFinalPrice] = useState(null);
    const [error, setError] = useState(null);

    const editPrice = (value: string) => {
        const min = Number(offer?.targetPrice.split('-')[0]);
        const max = Number(offer?.targetPrice.split('-')[1]);
        const fixedMargin = Number(offer?.fixedMargin);
        if (
            Number(value) < min * 0.9 - fixedMargin ||
            Number(value) > max * 0.9 - fixedMargin
        ) {
            setError(
                'Le prix doit être compris entre ' +
                    (min * 0.9 - fixedMargin) +
                    ' € HT et ' +
                    (max * 0.9 - fixedMargin) +
                    ' € HT'
            );
            return;
        }
        setError(null);
        setFinalPrice(value);
    };

    const displayRealPrice = (price: string, fixedMargin: number) => {
        const values = price.replace('€', '').split('-');
        const min = Number(values[0]);
        const max = Number(values[1]);
        return `${min * 0.9 - fixedMargin}-${max * 0.9 - fixedMargin}`;
    };

    const selectOffer = () => {
        if (!finalPrice) {
            setError('Veuillez entrer un prix.');
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .post(
                `${CONFIG.apiUrl}/offer/${offer?._id}/select`,
                { finalPrice: finalPrice },
                config
            )
            .then((res) => {
                console.log(res.data);
                window.location.href = '/admin/dashboard';
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const initUser = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                setUser(res.data);
                if (res.data && res.data.type !== 'seller') {
                    window.location.href = '/admin/my-request';
                }
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    const getOffer = (id: string) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/marketplace/${id}`, config)
            .then((res) => {
                setOffer(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log('No user info found', err);
            });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('token') ||
            localStorage.getItem('token').length < 10
        )
            window.location.href = '/auth/sign-up';
        getOffer(params.id);
        initUser();
        // eslint-disable-next-line
    }, []);

    const InfoBox = (props: {
        icon?: any;
        title?: string;
        description?: string;
    }) => {
        const { icon, title, description } = props;
        return (
            <div className="w-full rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
                <div className="grid grid-cols-12 gap-0">
                    <div className="col-span-1 text-gray-900">{icon}</div>
                    <h4 className="col-span-10 text-lg font-bold text-gray-900 dark:text-white">
                        {title}
                    </h4>
                </div>
                <p className="mt-[12px] pr-2 text-sm text-gray-700 dark:text-white">
                    {description}
                </p>
            </div>
        );
    };

    return (
        <div>
            <GoBack />
            <div className="mt-3 h-full w-full gap-2 rounded-[20px]">
                <Card
                    extra={
                        'relative w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                    }
                >
                    {/* Header */}
                    <div className="mt-1 flex gap-10">
                        <div>
                            {getImage(offer?.industry, offer?.subIndustry) ? (
                                <img
                                    src={getImage(
                                        offer?.industry,
                                        offer?.subIndustry
                                    )}
                                    className="mb-1 max-h-[500px] w-full rounded-xl"
                                    alt={`${offer?.industry} ${offer?.subIndustry}`}
                                />
                            ) : (
                                <div className="flex h-[500px] w-[500px] items-center justify-center rounded-xl bg-gray-500">
                                    <p>
                                        {offer?.industry} - {offer?.subIndustry}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="mt-5 text-xl text-navy-700 dark:text-white">
                                {offer?.subIndustry}
                            </p>
                            <h1 className="mt-2 text-2xl font-bold">
                                {offer?.industry}
                            </h1>
                            <p className="mb-5 text-sm font-bold">
                                #{offer?.offerNumber}
                            </p>
                            <div className="mb-5 mt-5 h-px w-full bg-gray-500"></div>
                            <div className="w-full">
                                {offer?.status === 'done' && (
                                    <InfoBox
                                        icon={<MdDoneOutline size={30} />}
                                        title={'Mission terminée'}
                                        description={`Vous avez généré ${
                                            offer?.leadsGenerated
                                        } (${
                                            offer?.leadsGenerated *
                                            offer?.sellerPrice.toFixed(2)
                                        }€) leads. Profitez de cette traction et cherchez un autre acheteur avec le même besoin`}
                                    />
                                )}
                                {offer?.status === 'running' && (
                                    <InfoBox
                                        icon={
                                            <MdOutlineRocketLaunch size={30} />
                                        }
                                        title={'Mission en cours'}
                                        description={`Vous avez déjà généré ${
                                            offer?.leadsGenerated *
                                            offer?.sellerPrice.toFixed(2)
                                        }€ (${
                                            offer?.leadsGenerated
                                        } leads) leads pour cette mission. Le payement est effectué chaque semaine sur votre compte bancaire.`}
                                    />
                                )}
                                {offer?.status === 'waiting_payment' && (
                                    <InfoBox
                                        icon={<MdOutlinePayment size={30} />}
                                        title={'En attente de paiement'}
                                        description={`Vous pourrez commencer votre campagne une fois la commande payée par
					l'acheteur. En attendant, si un lead est généré, nous nous assurons de
					vous le rediriger par mail.`}
                                    />
                                )}
                                {!user?.isKYCVerified && (
                                    <InfoBox
                                        icon={<MdOutlinePayment size={30} />}
                                        title={
                                            'Coordonées bancaires manquantes'
                                        }
                                        description={`Avant de vous positionner sur une offre, vous devez renseigner vos coordonées bancaires pour recevoir vos paiements.`}
                                    />
                                )}
                                {!user?.isValidated && (
                                    <p className="text-red-400">
                                        Nous sommes en train de vérifier votre
                                        compte. Vous ne pouvez pas encore
                                        postuler à une offre.
                                    </p>
                                )}
                                {offer?.status === 'on_market' &&
                                    user?.isKYCVerified &&
                                    user?.isValidated && (
                                        <div className="rounded-2xl bg-lightPrimary px-3 py-3 ">
                                            <InputField
                                                label="Prix final"
                                                placeholder={
                                                    displayRealPrice(
                                                        offer?.targetPrice,
                                                        offer?.fixedMargin
                                                    ).split('-')[1]
                                                }
                                                id="finalPrice"
                                                type="number"
                                                onChange={(e: any) => {
                                                    editPrice(e.target.value);
                                                }}
                                            />
                                            <p className="text-md mb-[20px] ml-2 text-red-500">
                                                {error}
                                            </p>
                                            <p className="ml-2 text-sm">
                                                Rémunération total ={' '}
                                                {offer?.weeklyQuantity} leads *{' '}
                                                {finalPrice}€ ={' '}
                                                {finalPrice *
                                                    offer?.weeklyQuantity}
                                                € HT
                                            </p>
                                            <button
                                                className="linear mt-2 w-full rounded-xl bg-leadrs px-3 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                                onClick={selectOffer}
                                            >
                                                Vendre{' '}
                                                {displayRealPrice(
                                                    offer?.targetPrice,
                                                    offer?.fixedMargin
                                                )}
                                                € HT par lead
                                            </button>
                                        </div>
                                    )}
                            </div>
                            <div className="mb-1 mt-5 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Quantité
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.weeklyQuantity} leads
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Champs de données
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.fields?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Canaux d'acquisition
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.channels?.join(', ')}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">Besoin</p>
                                <p className="mb-2 text-sm">{offer?.type}</p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Site web
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.websiteInfo}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-1 text-sm font-bold">
                                    Description
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.description}
                                </p>
                            </div>
                            <div className="mb-1 items-center gap-5">
                                <p className="mb-2 text-sm font-bold">
                                    {' '}
                                    Départements:{' '}
                                </p>
                                <p className="mb-2 text-sm">
                                    {offer?.regions?.join(', ')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default SellerMarketPlacePage;
