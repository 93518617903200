import { useState } from 'react';
import Card from 'components/card';
import SalesForceImg from 'assets/salesforce.png';
import HubspotImg from 'assets/hubspot.png';
import PipedriveImg from 'assets/pipedrive.png';
import ActiveCampaingImg from 'assets/activecampaign.png';
import MondayImg from 'assets/monday.png';
import GsheetImg from 'assets/gsheet.png';
import CheelyImg from 'assets/chelly.svg';
import { useNavigate } from 'react-router-dom';

const Integrate = () => {
    const [loading] = useState(false);
    const [services] = useState([
        {
            name: 'SalesForce',
            service: 'meta',
            description:
                'Recevez vos leads directement dans votre compte SalesForce.',
            logo: SalesForceImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'Chelly',
            service: 'chelly',
            description:
                'Recevez vos leads directement dans votre compte Chelly.',
            logo: CheelyImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'Hubspot',
            service: 'gmb',
            description:
                'Recevez vos leads directement dans votre compte Hubspot.',
            logo: HubspotImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'PipeDrive',
            service: 'fork',
            description:
                'Recevez vos leads directement dans votre compte PipeDrive.',
            logo: PipedriveImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'Monday',
            service: 'fork',
            description:
                'Recevez vos leads directement dans votre compte Monday.',
            logo: MondayImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'Active Campaign',
            service: 'fork',
            description:
                'Recevez vos leads directement dans votre compte Active Campaign.',
            logo: ActiveCampaingImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
        {
            name: 'Google Sheet',
            service: 'gsheet',
            description:
                'Recevez vos leads directement depuis votre compte Google Sheet.',
            logo: GsheetImg,
            status: 'coming-soon',
            onLogin: () => {},
        },
    ]);

    const PluginCard = (props: {
        logo: any;
        name: string;
        service: string;
        description: string;
        status?: string;
        index: number;
        onLogin?: () => void;
    }) => {
        const { logo, name, description, service, status, onLogin, index } =
            props;
        const nav = useNavigate();

        return (
            <Card
                extra={'relative w-full mt-4 px-2 py-6 border'}
                index={index + 1}
                key={index + 1}
            >
                {/* Badge */}
                {status === 'online' && (
                    <div className="absolute right-3 top-3 rounded-lg bg-green-600/80 px-2 py-1 text-xs text-white">
                        {'Connecté'}
                    </div>
                )}
                {status === 'offline' && (
                    <div className="absolute right-3 top-3 rounded-lg bg-red-600/80 px-2 py-1 text-xs text-white">
                        {'Déconnecté'}
                    </div>
                )}
                {status === 'not-setup' && (
                    <div className="absolute right-3 top-3 rounded-lg bg-orange-600/80 px-2 py-1 text-xs text-white">
                        {'Non configuré'}
                    </div>
                )}
                {/* Header */}
                <div className="w-full px-1">
                    <img
                        src={logo}
                        alt={`${name}-logo`}
                        className="ml-1 h-[70px] w-[70px] rounded-lg border"
                    />
                    <h4 className="mt-4 text-xl font-medium">{name}</h4>
                    <p className="mt-2 text-xs">{description}</p>
                </div>
                <div className="mt-4 flex justify-center">
                    {status === 'coming-soon' && (
                        <button
                            className={`linear flex h-10 w-full cursor-not-allowed items-center justify-center rounded-xl bg-leadrs px-5 py-3 text-sm font-medium text-white`}
                        >
                            Bientôt disponible
                        </button>
                    )}
                    {status === 'not-setup' && (
                        <button
                            className={`linear flex h-10 w-full cursor-pointer items-center justify-center rounded-xl bg-leadrs px-5 py-3 text-sm font-medium text-white`}
                            onClick={onLogin}
                        >
                            Associer un compte
                        </button>
                    )}
                    {status === 'online' && (
                        <button
                            className={`linear bg-primary flex h-10 w-full cursor-pointer items-center justify-center rounded-xl px-5 py-3 text-sm font-medium text-white`}
                            onClick={() => nav(`/integrate/${service}`)}
                        >
                            Gérer les paramètres
                        </button>
                    )}
                    {status === 'offline' && (
                        <button
                            className={`linear bg-primary flex h-10 w-full cursor-pointer items-center justify-center rounded-xl px-5 py-3 text-sm font-medium text-white`}
                            onClick={onLogin}
                        >
                            Me reconnecter
                        </button>
                    )}
                </div>
            </Card>
        );
    };

    return (
        <div>
            {loading ? (
                <div className="rounded-xl border-2 border-[#1f2937] p-10">
                    <p className="font-han mb-[80px] text-center text-2xl">
                        Chargement des extensions...
                    </p>
                </div>
            ) : (
                <>
                    <Card
                        extra={
                            'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                        }
                    >
                        {/* Header */}
                        <div className="mt-1">
                            <p className="text-xl font-semibold text-navy-700 dark:text-white">
                                Intégrations 🔑
                            </p>
                            <p className="mt-2 text-base text-gray-600">
                                Connectez votre CRM à Leadrs pour recevoir
                                automatiquement vos leads, simplifiant ainsi la
                                gestion et le suivi de vos prospects.
                            </p>
                        </div>
                    </Card>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                        {services.map((service, index) => (
                            <PluginCard
                                index={index}
                                key={service.service}
                                name={service.name}
                                service={service.service}
                                description={service.description}
                                logo={service.logo}
                                status={service.status}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Integrate;
