import Card from 'components/card';
import BuyerMarketPlaceCard from './components/OfferCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import { getImage } from 'variables/images';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [offer, setOffer] = useState([]);
    const [user, setUser] = useState(null);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const nav = useNavigate();

    const initOffer = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${CONFIG.apiUrl}/seller-offer/marketplace`, config)
            .then((res) => {
                const offers = res.data;
                setOffer(offers);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                const user = res.data;
                setUser(user);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                window.location.href = '/auth/sign-in';
            });
    };

    useEffect(() => {
        initOffer();
        initUser();
    }, []);

    const Headline = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Nos offres 📦 
                    </p>
                    <p className="mt-2 whitespace-pre-line text-base text-gray-600">
                        Retrouvez toutes les offres disponibles, explorez les
                        options qui vous intéressent et choisissez celles qui
                        correspondent le mieux à vos besoins.{'\n'} Si vous ne
                        trouvez pas l’offre qui vous convient vous pouvez créer
                        une nouvelle offre.
                    </p>
                </div>
            </Card>
        );
    };

    return (
        <div className="flex h-full w-full flex-col rounded-[20px] xl:flex-row ">
            <div className="h-full w-full rounded-[20px]">
                {/* left side */}
                <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                    {/* Your Transfers & tables */}
                    <div className="grid w-full grid-cols-12 gap-2">
                        <div className="col-span-12">
                            <div className={'h-full w-full px-1'}>
                                <Headline />
                                <div className="mt-3 grid grid-cols-12">
                                    <div className="col-span-12 mx-2 flex justify-between">
                                        <div>
                                            <select
                                                name="HeadlineAct"
                                                id="HeadlineAct"
                                                onChange={(e: any) =>
                                                    setSelectedIndustry(
                                                        e.target.value
                                                    )
                                                }
                                                className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
                                            >
                                                <option value="">
                                                    Toutes les offres
                                                </option>
                                                {offer
                                                    ?.map(
                                                        (item) => item.industry
                                                    )
                                                    .filter(
                                                        (value, index, self) =>
                                                            self.indexOf(
                                                                value
                                                            ) === index
                                                    )
                                                    .map((industry) => (
                                                        <option
                                                            value={industry}
                                                        >
                                                            {industry}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        {/* <div className="">
                                            {user &&
                                                user.role !== 'paying-user' && (
                                                    <button
                                                        className="linear flex items-center justify-center rounded-xl bg-brand-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/admin/subscribe')
                                                        }
                                                    >
                                                        S'abonner
                                                    </button>
                                                )}
                                        </div> */}
                                    </div>
                                </div>
                                {isLoading ? (
                                    <>
                                        <div className="h-50 mt-[100px] flex items-center justify-center">
                                            <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-t-2 border-leadrs"></div>
                                        </div>
                                        <p className="mt-3 text-center text-leadrs">
                                            Récupération des offres...
                                        </p>
                                    </>
                                ) : (
                                    <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
                                        {user &&
                                            user.role &&
                                            user.role !== 'paying-user' && (
                                                <Card
                                                    extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 border-leadrs border-dashed shadow-xl cursor-pointer`}
                                                >
                                                    <div className="mx-auto my-auto items-center text-center">
                                                        <h1 className="text-2xl font-bold text-leadrs">
                                                            Toujours<br></br>
                                                            pas abonné ?
                                                        </h1>
                                                        <button
                                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                                            onClick={() =>
                                                                (window.location.href =
                                                                    '/admin/subscribe')
                                                            }
                                                        >
                                                            Voir nos offres
                                                        </button>
                                                    </div>
                                                </Card>
                                            )}
                                        {offer
                                            ?.filter(
                                                (item) =>
                                                    item.industry ===
                                                        selectedIndustry ||
                                                    !selectedIndustry
                                            )
                                            .slice(0, 3)
                                            .map((item) => (
                                                <BuyerMarketPlaceCard
                                                    channels={item.channels}
                                                    offerNumber={
                                                        item.offerBuyerNumber
                                                    }
                                                    industry={item.industry}
                                                    subIndustry={
                                                        item.subIndustry
                                                    }
                                                    targetPrice={
                                                        item.targetPrice
                                                    }
                                                    weeklyQuantity={Number(
                                                        item.weeklyQuantity
                                                    )}
                                                    leadsGenerated={Number(
                                                        item.leadsGenerated
                                                    )}
                                                    otherIndustry={
                                                        item.otherIndustry
                                                    }
                                                    buyerPrice={item.buyerPrice.toFixed(
                                                        2
                                                    )}
                                                    user={user}
                                                    status={
                                                        user &&
                                                        user.role ===
                                                            'paying-user'
                                                            ? item.status
                                                            : 'not-paid'
                                                    }
                                                    image={getImage(
                                                        item.industry,
                                                        item.subIndustry
                                                    )}
                                                    onClick={() => {
                                                        nav(
                                                            `/admin/market-offer/${item._id}`
                                                        );
                                                    }}
                                                />
                                            ))}
                                        {user &&
                                            user.role &&
                                            user.role !== 'user' && (
                                                <Card
                                                    extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 border-leadrs border-dashed shadow-xl cursor-pointer`}
                                                >
                                                    <div className="mx-auto my-auto items-center text-center">
                                                        <h1 className="text-2xl font-bold text-leadrs">
                                                            Besoin de<br></br>
                                                            nouveaux leads ?
                                                        </h1>
                                                        <button
                                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                                            onClick={() =>
                                                                (window.location.href =
                                                                    'create-order')
                                                            }
                                                        >
                                                            Passez commande
                                                        </button>
                                                    </div>
                                                </Card>
                                            )}
                                        {offer
                                            ?.filter(
                                                (item) =>
                                                    item.industry ===
                                                        selectedIndustry ||
                                                    !selectedIndustry
                                            )
                                            .slice(3)
                                            .map((item) => (
                                                <BuyerMarketPlaceCard
                                                    channels={item.channels}
                                                    offerNumber={
                                                        item.offerBuyerNumber
                                                    }
                                                    industry={item.industry}
                                                    subIndustry={
                                                        item.subIndustry
                                                    }
                                                    targetPrice={
                                                        item.targetPrice
                                                    }
                                                    weeklyQuantity={Number(
                                                        item.weeklyQuantity
                                                    )}
                                                    leadsGenerated={Number(
                                                        item.leadsGenerated
                                                    )}
                                                    otherIndustry={
                                                        item.otherIndustry
                                                    }
                                                    buyerPrice={item.buyerPrice.toFixed(
                                                        2
                                                    )}
                                                    user={user}
                                                    status={
                                                        user &&
                                                        user.role ===
                                                            'paying-user'
                                                            ? item.status
                                                            : 'not-paid'
                                                    }
                                                    image={getImage(
                                                        item.industry,
                                                        item.subIndustry
                                                    )}
                                                    onClick={() => {
                                                        nav(
                                                            `/admin/market-offer/${item._id}`
                                                        );
                                                    }}
                                                />
                                            ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
