import Card from 'components/card';
import OrderStep from 'components/dataDisplay/OrderStep';
import {
    MdApproval,
    MdOutlineSettingsSuggest,
    MdOutlinePayments,
    MdOutlineHourglassTop,
    MdDoneOutline,
} from 'react-icons/md';
import { MdCheck, MdTimer } from 'react-icons/md';
import { OfferStatus } from 'variables/status';

const icons = [
    <MdApproval />,
    <MdOutlineSettingsSuggest />,
    <MdOutlinePayments />,
    <MdOutlineHourglassTop />,
    <MdDoneOutline />,
];

const OrderStatus = (props: { offer?: any }) => {
    const { offer } = props;
    return (
        <Card extra={'border w-full h-[380px] p-4'}>
            {/* Order Status */}
            <h4 className="text-lg font-semibold text-navy-700 dark:text-white">
                État de ma commande
            </h4>
            {/* ordersteps */}
            <div className="relative h-full w-full">
                {/* steps */}
                <div className="font-dm font-medium">
                    {/* Display all OfferStatus elements */}
                    {offer &&
                        Object.values(OfferStatus).map(
                            (status: any, index: any) => (
                                <>
                                    <div className="">
                                        <OrderStep
                                            title={status}
                                            subtitle={`${
                                                offer &&
                                                `${offer.industry} - ${offer.subIndustry}`
                                            }`}
                                            bg="bg-white dark:!bg-navy-800"
                                            isDone={
                                                Object.keys(
                                                    OfferStatus
                                                ).indexOf(offer.status) >= index
                                            }
                                            icon2={
                                                Object.keys(
                                                    OfferStatus
                                                ).indexOf(offer.status) >
                                                index ? (
                                                    <MdCheck />
                                                ) : (
                                                    <MdTimer />
                                                )
                                            }
                                            icon={icons[index] as any}
                                            border={`border ${
                                                Object.keys(
                                                    OfferStatus
                                                ).indexOf(offer.status) >= index
                                                    ? 'border-green-500'
                                                    : 'border-gray-500'
                                            }`}
                                        />
                                    </div>
                                    {offer &&
                                        index !==
                                            Object.values(OfferStatus).length -
                                                1 && (
                                            <div
                                                className={`absolute left-[32px] ${
                                                    Object.keys(
                                                        OfferStatus
                                                    ).indexOf(offer.status) >=
                                                    index + 1
                                                        ? 'text-leadrs'
                                                        : 'text-gray-300'
                                                }`}
                                            >
                                                <svg
                                                    width="2"
                                                    height="80"
                                                    viewBox="0 0 2 64"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <line
                                                        x1="1"
                                                        y1="63"
                                                        x2="0.999997"
                                                        y2="1"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-dasharray="6 6"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                </>
                            )
                        )}
                </div>
            </div>
        </Card>
    );
};

export default OrderStatus;
