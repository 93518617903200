import Logo from 'assets/new-logo.png';

const Navbar = (props: {
    onOpenSidenav: () => void;
    brandText: string;
    secondary?: boolean | string;
}) => {
    // function onOpenSidenav(event: any): void {
    //   throw new Error('Function not implemented.');
    // }

    // function previousOne() {
    //   return (
    //     <div className="grid grid-cols-12">
    //       <nav className="fixed top-3 ml-5 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 backdrop-blur-xl dark:bg-[#0b14374d]">
    //         {!window.location.href.includes('new-offer') && (
    //           <>
    //             <div className="col-span-10 ml-10 mt-8 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
    //               Leadrs.co
    //             </div>
    //           </>
    //         )}
    //         <div className="mt-5 flex h-[61px] w-[180px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[180px] md:flex-grow-0 md:gap-1 xl:w-[180px] xl:gap-2">
    //           <span
    //             className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
    //             onClick={onOpenSidenav}
    //           >
    //             <FiAlignJustify className="h-5 w-5" />
    //           </span>

    //           <p className="align-middle text-[14px] font-bold capitalize text-navy-700">
    //             {' '}
    //             Mon Compte{' '}
    //           </p>
    //           <div className="col-span-12">
    //             <Dropdown
    //               button={
    //                 <img
    //                   className="h-10 w-10 rounded-full"
    //                   src={avatar}
    //                   alt="Elon Musk"
    //                 />
    //               }
    //               children={
    //                 <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
    //                   <div className="ml-4 mt-3">
    //                     <div className="flex items-center gap-2">
    //                       <p className="text-sm font-bold text-navy-700 dark:text-white">
    //                         Bienvenue sur Leadrs 👋
    //                       </p>{' '}
    //                     </div>
    //                   </div>
    //                   <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

    //                   <div className="ml-4 mt-3 flex flex-col">
    //                     <a
    //                       href=" "
    //                       className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
    //                     >
    //                       Gérer mon profil
    //                     </a>
    //                     <a
    //                       href=" "
    //                       className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
    //                     >
    //                       Log Out
    //                     </a>
    //                   </div>
    //                 </div>
    //               }
    //               classNames={'py-2 top-8 -left-[180px] w-max'}
    //             />
    //           </div>
    //         </div>
    //       </nav>
    //     </div>
    //   );
    // }

    return (
        <div className="fixed top-3 ml-5 mt-3 w-[200px] gap-10">
            <img
                className="ml-7 mt-5 hidden lg:block"
                alt="Logo leadrs"
                src={Logo}
            ></img>
            {/* <div className="fixed right-10 top-10 rounded-xl border bg-white  px-5 py-2">
                <Dropdown
                    button={
                        <>
                            <p className="align-center align-middle text-[14px] font-bold capitalize text-navy-700">
                                {' '}
                                Mon Compte{' '}
                            </p>
                            <MdKeyboardArrowDown className="ml-2 mt-[2px]" />
                        </>
                    }
                    children={
                        <div className="flex h-max w-56 flex-col justify-start rounded-[20px] border bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                            <div className="ml-4 mt-3">
                                <div className="flex items-center gap-2">
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        Bienvenue sur Leadrs 👋
                                    </p>{' '}
                                </div>
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                            <div className="ml-4 mt-3 flex flex-col">
                                <a
                                    href=" "
                                    className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                                    onClick={() => {
                                        nav('/admin/settings');
                                    }}
                                >
                                    Gérer mon profil
                                </a>
                                <a
                                    href=" "
                                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                                    onClick={() => {
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('user');
                                        nav('/auth/sign-in');
                                    }}
                                >
                                    Log Out
                                </a>
                            </div>
                        </div>
                    }
                    classNames={'py-2 top-3 -left-[90px] w-max'}
                />
            </div> */}
        </div>
    );
};

export default Navbar;
