import {
    MdAnalytics,
    MdHome,
    MdKey,
    MdLock,
    MdLockOutline,
    MdMonetizationOn,
    MdOutlineLocalOffer,
    MdOutlinePeopleOutline,
} from 'react-icons/md';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCenter';
import LockCentered from 'views/auth/lock/LockCenter';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCenter';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import SignUpSeller from 'views/auth/signUp/SignUpSeller';
//import DashboardsDefault from 'views/admin/dashboards/default';
import SellerDashboard from 'views/admin/nfts/collection';
import ProfileSettings from 'views/admin/main/profile/settings';

// Admin Imports

// Main Imports

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import StripeBilling from 'views/admin/main/account/stripe';
import SellerOffer from 'views/admin/main/ecommerce/sellerOffer';
import BuyerOffer from 'views/admin/main/seller/buyerOffer';
import MyOffer from 'views/admin/main/seller/myOffer';
import { FaBorderAll, FaEye } from 'react-icons/fa';
import Integrate from 'views/admin/main/seller/integrate';
import SellerReporting from 'views/admin/main/seller/sellerReporting';
import SellerIntegration from 'views/admin/main/ecommerce/referrals';
import SellerPaiement from 'views/admin/main/seller/paiement';
import OfferPage from 'views/admin/main/seller/OfferPage';
import ResetPassword from 'views/auth/forgotPassword/ResetPassword';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault copy';
import SellerBenchMarkPage from 'views/offerPages/seller/SellerBenchMarkPage';
import SellerMarketPlacePage from 'views/offerPages/seller/SellerMarketPlacePage';
import SellerOfferPage from 'views/offerPages/seller/SellerOfferPage';
import MyLeads from 'views/admin/main/seller/myLeads';

const routes = [
    {
        name: 'Marketplace',
        layout: '/admin',
        path: '/dashboard',
        icon: <FaBorderAll className="text-inherit h-6 w-6" />,
        component: <BuyerOffer />,
        menu: true,
    },
    {
        name: 'Marketplace',
        layout: '/admin',
        path: '/market-offer/:id',
        icon: <FaBorderAll className="text-inherit h-6 w-6" />,
        component: <SellerMarketPlacePage />,
        menu: false,
    },
    // {
    //   name: 'Tableaux de bord',
    //   layout: '/admin',
    //   path: '/my-request',
    //   icon: <MdHome className="text-inherit h-6 w-6" />,
    //   component: <DashboardsDefault />,
    //   menu: false,
    // },
    {
        name: 'Profile Settings',
        layout: '/admin',
        path: '/settings',
        exact: false,
        icon: <MdHome className="text-inherit h-6 w-6" />,
        component: <ProfileSettings />,
    },
    {
        name: 'Mes offres',
        layout: '/admin',
        path: '/my-offer',
        icon: <MdOutlineLocalOffer className="text-inherit h-6 w-6" />,
        component: <MyOffer />,
        menu: true,
    },
    {
        name: 'Mes Leads',
        layout: '/admin',
        path: '/my-leads',
        icon: <MdOutlinePeopleOutline className="text-inherit h-6 w-6" />,
        component: <MyLeads />,
        menu: true,
    },
    {
        name: 'Mes offres',
        layout: '/admin',
        path: '/offer/:id',
        icon: <MdOutlineLocalOffer className="text-inherit h-6 w-6" />,
        component: <SellerOfferPage />,
        menu: false,
    },
    {
        name: 'Benchmark',
        layout: '/admin',
        path: '/benchmark',
        icon: <FaEye className="text-inherit h-6 w-6" />,
        component: <SellerDashboard />,
        menu: true,
    },
    {
        name: 'Benchmark',
        layout: '/admin',
        path: '/benchmark-offer/:id',
        icon: <FaEye className="text-inherit h-6 w-6" />,
        component: <SellerBenchMarkPage />,
        menu: false,
    },
    {
        name: 'Intégration',
        layout: '/admin',
        path: '/connect',
        icon: <MdKey className="text-inherit h-6 w-6" />,
        menu: true,
        component: <Integrate />,
    },
    {
        name: 'Reporting',
        layout: '/admin',
        path: '/reporting',
        icon: <MdAnalytics className="text-inherit h-6 w-6" />,
        menu: true,
        component: <SellerReporting />,
    },
    {
        name: 'Paiement',
        layout: '/admin',
        path: '/paiement',
        icon: <MdMonetizationOn className="text-inherit h-6 w-6" />,
        availble: true,
        menu: true,
        component: <SellerPaiement />,
    },
    {
        name: 'Attente de validation',
        layout: '/admin',
        path: '/seller/integrate',
        icon: <MdLockOutline className="text-inherit h-6 w-6" />,
        component: <SellerIntegration />,
    },
    {
        name: 'Créer une offre',
        layout: '/admin',
        path: '/new-offer',
        exact: false,
        icon: <MdLockOutline className="text-inherit h-6 w-6" />,
        component: <EcommerceNewProduct />,
    },
    {
        name: 'Créer une offre',
        layout: '/admin',
        path: '/seller-offer',
        exact: false,
        menu: false,
        icon: <MdLockOutline className="text-inherit h-6 w-6" />,
        component: <SellerOffer />,
    },
    {
        name: 'Abonnements',
        layout: '/admin',
        path: '/subscribe',
        exact: false,
        icon: <MdLockOutline className="text-inherit h-6 w-6" />,
        component: <StripeBilling />,
    },
    // --- Authentication ---
    {
        name: 'Authentication',
        path: '/auth',
        icon: <MdLock className="text-inherit h-5 w-5" />,
        collapse: true,
        items: [
            // --- Sign In ---
            {
                name: 'Sign In',
                path: '/sign-in',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/sign-in',
                        component: <SignInDefault />,
                    },
                ],
            },
            // --- Sign Up ---
            {
                name: 'Sign Up',
                path: '/sign-up',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/sign-up',
                        component: <SignUpDefault />,
                    },
                ],
            },
            {
                name: 'Sign Up',
                path: '/seller/sign-up',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/seller/sign-up',
                        component: <SignUpSeller />,
                    },
                ],
            },
            // --- Verification ---
            {
                name: 'Verification',
                path: '/verification',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/verification/default',
                        component: <VerificationDefault />,
                    },
                    {
                        name: 'Centered',
                        layout: '/auth',
                        path: '/verification/centered',
                        component: <VerificationCentered />,
                    },
                ],
            },
            // --- Lock ---
            {
                name: 'Lock',
                path: '/lock',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/lock/default',
                        component: <LockDefault />,
                    },
                    {
                        name: 'Centered',
                        layout: '/auth',
                        path: '/lock/centered',
                        component: <LockCentered />,
                    },
                ],
            },
            // --- Forgot Password ---
            {
                name: 'Forgot Password',
                path: '/forgot-password',
                collapse: true,
                items: [
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/forgot-password/default',
                        component: <ForgotPasswordDefault />,
                    },
                    {
                        name: 'Default',
                        layout: '/auth',
                        path: '/forgot-password/reset',
                        component: <ResetPassword />,
                    },
                    {
                        name: 'Centered',
                        layout: '/auth',
                        path: '/forgot-password/centered',
                        component: <ForgotPasswordCentered />,
                    },
                ],
            },
        ],
    },
];
export default routes;
