/* eslint-disable */

import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import Logo from 'assets/new-logo.png';
import {
    renderThumb,
    renderTrack,
    renderView,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import routes from 'routes';
import routesBuyer from 'routesBuyer';
import Card from 'components/card';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SidebarHorizon = (props: {
    open: boolean;
    onClose: React.MouseEventHandler<HTMLSpanElement>;
    variant?: string;
}) => {
    const { open, onClose, variant } = props;
    const nav = useNavigate();

    useEffect(() => {
        // if (localStorage.getItem("user-type") === "seller") {
        //   setRoutes(routes);
        // }
    }, []);

    return (
        <div
            className={`sm:none duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
                variant === 'auth' ? 'xl:hidden' : 'xl:block'
            } ${open ? '' : '-translate-x-full'}`}
        >
            <Card
                extra={`w-[285px] ml-3 sm:mr-4 sm:my-4 h-[96.5vh] m-7 !rounded-[20px]`}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <div className="relative flex h-full flex-col justify-between">
                        <div>
                            <span
                                className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                                onClick={onClose}
                            >
                                <HiX />
                            </span>
                            <div
                                className={`ml-[52px] mt-[44px] flex items-center`}
                            >
                                <div className="mx-auto mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white"></div>
                            </div>
                            <div className="mb-7 mt-[58px] h-px bg-gray-200 dark:bg-white/10" />
                            {/* Nav item */}
                            <ul className="ml-[10px] pt-1">
                                {localStorage.getItem('user-type') ===
                                'buyer' ? (
                                    <Links routes={routesBuyer} />
                                ) : (
                                    <Links routes={routes} />
                                )}
                                {localStorage.getItem('user-type') ===
                                    'buyer' && (
                                    <div className="mt-10">
                                        <button
                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                            onClick={() =>
                                                (window.location.href =
                                                    '/admin/create-order')
                                            }
                                        >
                                            Passez commande
                                        </button>
                                    </div>
                                )}
                                {localStorage.getItem('user-type') ===
                                    'seller' && (
                                    <div className="mt-10">
                                        <button
                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                            onClick={() =>
                                                nav('/admin/seller-offer')
                                            }
                                        >
                                            Ajouter une offre
                                        </button>
                                    </div>
                                )}
                                {/* Buyer Menu */}
                                {localStorage.getItem('user-type') ===
                                    'buyer' && (
                                    <div className="absolute bottom-10 left-9 mx-auto w-full">
                                        <div className="mx-auto justify-center">
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    nav('/admin/dashboard')
                                                }
                                            >
                                                FAQ
                                            </p>
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    window.open(
                                                        'https://go.crisp.chat/chat/embed/?website_id=d7156788-8d6d-40b5-ba31-f28c6b9dd7b0',
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                Assistance
                                            </p>
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    nav('/admin/settings')
                                                }
                                            >
                                                Mon compte
                                            </p>
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    window.open(
                                                        'https://billing.stripe.com/p/login/00g4iQ4aC0R76pW4gg',
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                Mon abonnement
                                            </p>
                                            <a
                                                href=" "
                                                className="mt-3 text-[10px] font-medium text-red-500 hover:text-red-500 hover:underline"
                                                onClick={() => {
                                                    localStorage.removeItem(
                                                        'token'
                                                    );
                                                    localStorage.removeItem(
                                                        'user'
                                                    );
                                                    nav('/auth/sign-in');
                                                }}
                                            >
                                                Se déconnecter
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {/* Seller Menu */}
                                {localStorage.getItem('user-type') ===
                                    'seller' && (
                                    <div className="absolute bottom-10 left-9 mx-auto w-full">
                                        <div className="mx-auto justify-center">
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    nav('/admin/dashboard')
                                                }
                                            >
                                                RGPD
                                            </p>
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    window.open(
                                                        'https://go.crisp.chat/chat/embed/?website_id=d7156788-8d6d-40b5-ba31-f28c6b9dd7b0',
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                Assistance
                                            </p>
                                            <p
                                                className="text-sm text-gray-600 hover:cursor-pointer hover:underline"
                                                onClick={() =>
                                                    nav('/admin/settings')
                                                }
                                            >
                                                Mon compte
                                            </p>
                                            <a
                                                href=" "
                                                className="mt-3 text-[10px] font-medium text-red-500 hover:text-red-500 hover:underline"
                                                onClick={() => {
                                                    localStorage.removeItem(
                                                        'token'
                                                    );
                                                    localStorage.removeItem(
                                                        'user'
                                                    );
                                                    nav('/auth/sign-in');
                                                }}
                                            >
                                                Se déconnecter
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </Scrollbars>
            </Card>
        </div>
    );
};

export default SidebarHorizon;
