import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';

import Card from 'components/card';
import BuyerMyOfferCard from 'components/card/OfferCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import { useNavigate } from 'react-router-dom';
import { getImage } from 'variables/images';
import SuccessModal from './components/SuccessModal';
import { useDisclosure } from '@chakra-ui/hooks';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from './components/ErrorModal';

const MyOffer = () => {
    const [offer, setOffer] = useState([]);
    const [user, setUser] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2,
    } = useDisclosure();
    const nav = useNavigate();

    const initOffer = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${CONFIG.apiUrl}/offer/mine`, config)
            .then((res) => {
                const offers = res.data;
                setOffer(offers);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                const user = res.data;
                setUser(user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        initOffer();
        initUser();
    }, []);

    useEffect(() => {
        if (!user) return;
        const urlParams = new URLSearchParams(window.location.search);
        const created = urlParams.get('created');
        const createdNotPaid = urlParams.get('not-paid');

        if (created === 'true' && !isOpen) {
            onOpen();
        }

        if (createdNotPaid === 'true' && !isOpen2) {
            onOpen2();
        }
    }, [user]);

    const Headline = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Mes commandes 🛒
                    </p>
                    <p className="mt-2 text-base text-gray-600">
                        Suivez l'évolution de vos commandes, gérez leur statut
                        et assurez-vous que tout se déroule correctement à
                        chaque étape.
                    </p>
                </div>
            </Card>
        );
    };

    return (
        <div>
            <ToastContainer />
            <SuccessModal isOpen={isOpen} onClose={onClose} />
            <ErrorModal isOpen={isOpen2} onClose={onClose2} />
            <div className="flex h-full w-full flex-col rounded-[20px] xl:flex-row ">
                <div className="h-full w-full rounded-[20px]">
                    {/* left side */}
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Your Transfers & tables */}
                        <div className="grid w-full grid-cols-12 gap-2">
                            <div className="col-span-12">
                                <div className={'h-full w-full px-1'}>
                                    <Headline />
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-12 flex justify-between">
                                            {/* <div className="">
                                            {user &&
                                                user.role !== 'paying-user' && (
                                                    <button
                                                        className="linear flex items-center justify-center rounded-xl bg-brand-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/admin/subscribe')
                                                        }
                                                    >
                                                        S'abonner
                                                    </button>
                                                )}
                                        </div> */}
                                        </div>
                                    </div>
                                    {offer && offer.length === 0 && (
                                        <div className="mt-5 justify-center">
                                            <p className="flex justify-center text-xl text-gray-400">
                                                Vous n'avez pas encore passé
                                                commande
                                            </p>
                                            <button
                                                className="text-md mx-auto mt-5 flex rounded-xl bg-leadrs px-6 py-3 font-medium text-white transition duration-200 hover:bg-leadrsLight"
                                                onClick={() =>
                                                    nav('/admin/create-order')
                                                }
                                            >
                                                Passer ma première commande
                                            </button>
                                        </div>
                                    )}
                                    <div className="mt-7 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
                                        {user &&
                                            user.role &&
                                            user.role !== 'paying-user' && (
                                                <Card
                                                    extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 border-leadrs border-dashed shadow-xl cursor-pointer`}
                                                >
                                                    <div className="mx-auto my-auto items-center text-center">
                                                        <h1 className="text-2xl font-bold text-leadrs">
                                                            Toujours<br></br>
                                                            pas abonné ?
                                                        </h1>
                                                        <button
                                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                                            onClick={() =>
                                                                (window.location.href =
                                                                    '/admin/subscribe')
                                                            }
                                                        >
                                                            Voir nos offres
                                                        </button>
                                                    </div>
                                                </Card>
                                            )}
                                        {offer.slice(0, 3).map((item) => (
                                            <BuyerMyOfferCard
                                                bidders={[
                                                    avatar1,
                                                    avatar2,
                                                    avatar3,
                                                ]}
                                                industry={item.industry}
                                                channels={item.channels}
                                                offerNumber={
                                                    item.offerBuyerNumber
                                                }
                                                subIndustry={item.subIndustry}
                                                targetPrice={item.targetPrice}
                                                weeklyQuantity={Number(
                                                    item.weeklyQuantity
                                                )}
                                                leadsGenerated={Number(
                                                    item.leadsGenerated
                                                )}
                                                otherIndustry={
                                                    item.otherIndustry
                                                }
                                                user={user}
                                                status={
                                                    user &&
                                                    user.role === 'paying-user'
                                                        ? item.status
                                                        : 'not-paid'
                                                }
                                                image={getImage(
                                                    item.industry,
                                                    item.subIndustry
                                                )}
                                                onClick={() => {
                                                    nav(
                                                        `/admin/my-offers/${item._id}`
                                                    );
                                                }}
                                            />
                                        ))}
                                        {user &&
                                            user.role &&
                                            user.role !== 'user' && (
                                                <Card
                                                    extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 border-leadrs border-dashed shadow-xl cursor-pointer`}
                                                >
                                                    <div className="mx-auto my-auto items-center text-center">
                                                        <h1 className="text-2xl font-bold text-leadrs">
                                                            Besoin de<br></br>
                                                            nouveaux leads ?
                                                        </h1>
                                                        <button
                                                            className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                                            onClick={() =>
                                                                (window.location.href =
                                                                    '/admin/create-order')
                                                            }
                                                        >
                                                            Passez commande
                                                        </button>
                                                    </div>
                                                </Card>
                                            )}
                                        {offer.slice(3).map((item) => (
                                            <BuyerMyOfferCard
                                                bidders={[
                                                    avatar1,
                                                    avatar2,
                                                    avatar3,
                                                ]}
                                                industry={item.industry}
                                                channels={item.channels}
                                                offerNumber={
                                                    item.offerBuyerNumber
                                                }
                                                subIndustry={item.subIndustry}
                                                targetPrice={item.targetPrice}
                                                weeklyQuantity={Number(
                                                    item.weeklyQuantity
                                                )}
                                                leadsGenerated={Number(
                                                    item.leadsGenerated
                                                )}
                                                otherIndustry={
                                                    item.otherIndustry
                                                }
                                                user={user}
                                                status={
                                                    user &&
                                                    user.role === 'paying-user'
                                                        ? item.status
                                                        : 'not-paid'
                                                }
                                                image={getImage(
                                                    item.industry,
                                                    item.subIndustry
                                                )}
                                                onClick={() => {
                                                    nav(
                                                        `/admin/my-offers/${item._id}`
                                                    );
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-span-12 sm:col-span-3">
              <OrderStatus offer={offer[0]} />
            </div> */}
                            {/* <div className="col-span-6 3xl:col-span-2">
              <YourCard />
            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyOffer;
