import Card from 'components/card';
import { ChannelIcon } from 'components/icons';

const SellerMarketplaceCard = (props: {
    image: string;
    title: string;
    author: string;
    download?: string;
    price: string | number;
    weeklyQuantity?: string | number;
    extra?: string;
    status?: string;
    buttonDisabled?: boolean;
    isKYCVerified?: boolean;
    isValidated?: boolean;
    offerNumber: string;
    isArchived?: boolean;
    channels: any;
    onClick?: () => void;
}) => {
    const {
        title,
        author,
        price,
        image,
        extra,
        isArchived,
        weeklyQuantity,
        onClick,
        status,
        isKYCVerified,
        isValidated,
        offerNumber,
        channels,
    } = props;
    return (
        <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] min-w-[250px] border-2 cursor-pointer hover:shadow-xl bg-white ${extra}`}
            onClick={onClick}
        >
            <div className="h-full w-full">
                <div className="relative w-full">
                    <img
                        src={image}
                        className="mb-3 max-h-[130px] w-full rounded-xl 3xl:h-full 3xl:w-full"
                        alt=""
                    />
                    {!image && (
                        <div className="flex h-[130px] w-full items-center justify-center gap-5 rounded-xl bg-gray-200 dark:bg-white/10">
                            <p className="text-xl font-bold text-gray-400 dark:text-white">
                                {title}
                            </p>
                            <p className="text-sm font-medium text-gray-400 dark:text-white">
                                {author}
                            </p>
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="w-full">
                        <div className="flex gap-2">
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                {' '}
                                {title}{' '}
                            </p>
                            <p className="my-auto flex text-xs font-bold text-gray-600">
                                #{offerNumber}
                            </p>
                        </div>
                        <div className="flex w-full justify-between">
                            <p className="text-sm font-medium text-gray-600 md:mt-2">
                                {author}
                            </p>
                            <p className="text-sm font-bold text-leadrs dark:text-white md:mt-2">
                                {weeklyQuantity} leads
                            </p>
                        </div>
                        {isArchived && (
                            <p className="mt-2 font-bold text-green-500">
                                Mission terminée
                            </p>
                        )}
                    </div>
                </div>

                <div className="ml-1 mt-3 flex gap-2">
                    {channels?.map((channel: string) => (
                        <div className="text-leadrs">
                            <ChannelIcon channel={channel} />
                        </div>
                    ))}
                </div>
                <div className="mt-4">
                    {status === 'on_market' && isKYCVerified && isValidated ? (
                        <button
                            className="linear mx-auto flex rounded-[20px] bg-leadrs px-4 py-2 text-xs font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 lg:text-sm"
                            onClick={onClick}
                        >
                            Vendre {price}€ HT par leads
                        </button>
                    ) : (
                        <>
                            <button
                                className="linear mx-auto rounded-[20px] bg-leadrs px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                                onClick={onClick}
                            >
                                Visualiser
                            </button>
                        </>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default SellerMarketplaceCard;
