import Card from 'components/card';
const MyLeads = () => {
    return (
        <>
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Mes leads 🎯
                    </p>
                    <p className="mt-2 text-base text-gray-600">
                        Consultez vos prospects, identifiez ceux qui présentent
                        le plus grand potentiel et ajustez vos actions pour
                        maximiser vos conversions.
                    </p>
                </div>
            </Card>
        </>
    );
};

export default MyLeads;
