import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Card from 'components/card';
import { MdClose } from 'react-icons/md';

const ErrorModal = (props: { isOpen: any; onClose: any }) => {
    const { isOpen, onClose } = props;

    return (
        <div className="relative">
            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] border-none p-0 shadow-none focus:outline-none md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[750px] flex flex-col !z-[1004] h-[320px]">
                            <div className="absolute right-5 top-5">
                                <MdClose
                                    size={20}
                                    className="hover: cursor-pointer text-red-600 hover:text-red-700"
                                    onClick={onClose}
                                />
                            </div>
                            <p className="text-center text-xl font-bold">
                                Demande en attente ⛔
                            </p>
                            <p className="text-md mt-8 text-center">
                                Nous vous remercions pour votre demande, mais
                                celle-ci ne peut pas être prise en compte pour
                                le moment.
                            </p>
                            <p className="text-md mt-4 text-center">
                                Pour pouvoir poster votre annonce, il est
                                nécessaire de{' '}
                                <span
                                    className="cursor-pointer font-bold text-leadrs hover:underline"
                                    onClick={() =>
                                        (window.location.href =
                                            '/admin/subscribe')
                                    }
                                >
                                    souscrire à un abonnement
                                </span>{' '}
                                payant ou d'utiliser un code de parrainage.
                                N'hésitez pas à nous contacter si vous avez des
                                questions.
                            </p>
                            <button
                                className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                                onClick={() =>
                                    (window.location.href = '/admin/subscribe')
                                }
                            >
                                Je m'abonne maintenant
                            </button>
                            <p className="mr-3 mt-4 flex justify-center">
                                L’équipe Leadrs
                            </p>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
export default ErrorModal;
